<template>
  <div id="MapaEmpreendimentos" style="width:100%" >

	<div ref="map" id="map" class="olMap" v-bind:style="{ width: '100%', height: getHeight()+'px' } " >
		<div style="display:none">{{ store_EmpreendimentoPopup }}</div>
		<div id="popup1">

			<!-- Popup do Empreendimento no Mapa ----------------------- -->
			<v-container class="grey lighten-5 mb-1">
				<v-row align="center" no-gutters style="padding:0px">
					<v-col cols="1" align="left" style="top:-30px; left:-30px">

					<!-- Avatar do Logotipo --------------- -->
					<v-avatar class="ml-n10 mt-n10 profil" color="white" size="50" >
						<v-img
							width="100%"
							height="100%"
							contain
							v-bind:src="store_Empreendimentos.empreend_selecionado_hover.empreend_logo"
						></v-img>
					</v-avatar>

					</v-col>
					<v-col cols="11" align="center" style="top:-5px">
                      <span @click="VueRoute()">
							<router-link class="name" :to="{ name: '/EmpreendimentoDetalhe', params: { cod_empreendimento: retorna_CodEmp_Popup } }" >
								{{ store_Empreendimentos.empreend_selecionado_hover.empreend_nome }}
							</router-link>
						</span>
						<!-- <router-link :to="{ path: 'EmpreendimentoDetalhe?cod_empreendimento=' + retorna_CodEmp }">Home</router-link> -->
						<!-- <h2>
							<a class="card-title font-small" :href="`EmpreendimentoDetalhe?cod_empreendimento=` + store_Empreendimentos.empreend_selecionado_hover.cod_empreendimento">
								{{ store_Empreendimentos.empreend_selecionado_hover.empreend_nome }}
							</a>
							<a href="#" @click="VueRoute()">Another link</a>
						</h2> -->
					</v-col>
				</v-row>
				<v-row align="center" no-gutters style="padding:0px">
					<v-col cols="12" align="center" style="top:-10px">
						<span class="homes-tag featured caption rounded px-2 py-2" >
							Lotes <span class="caption font-weight-bold">disponíveis</span>: {{ store_Empreendimentos.empreend_selecionado_hover.quant_unidades }}
						</span>
					</v-col>
				</v-row>
				<v-row align="center" style="padding:0px">
						<div class="footer d-flex ">
							<v-col cols="7" align="center" style="top:-10px; background-color:transparent">
								<div class="footer-value d-flex flex-column">
									<span class="font-small">Valores</span>
									<span class="font-weight-black"
										>R$ {{ formatNumber(store_Empreendimentos.empreend_selecionado_hover.preco_min) }} a R$ {{ formatNumber(store_Empreendimentos.empreend_selecionado_hover.preco_max) }}
									</span>
								</div>
							</v-col>
							<v-col cols="5" align="center" style="top:-10px; background-color:transparent">
								<div class="footer-value d-flex flex-column">
								<!-- <v-icon class="fa fa-object-group mr-2 mb-1"
									>mdi-vector-square</v-icon
								> -->
								<span class="font-small">Áreas</span>
								<span class="font-weight-black"
									>{{ formatNumber(store_Empreendimentos.empreend_selecionado_hover.area_min) }}m² a {{ formatNumber(store_Empreendimentos.empreend_selecionado_hover.area_max) }}m²
								</span>
								</div>
							</v-col>
						</div>
				</v-row>
			</v-container>
			<!-- FIM Popup do Empreendimento no Mapa ----------------------- -->

		</div>

		<div v-if="store_Empreendimentos.botao_visible" id="coordexterne">
	        <div >
				<v-btn
				id="botoes"
				class="fa fa-arrow-circle-left"
				fab
				dark
				small
				color="primary"
				v-on:click="store_Empreendimentos.styleSelecao = 0;
				            store_Empreendimentos.botao_visible = false;
							//store_Empreendimento.popupSelecao = 0;
							fitBounds(0);
						   " >
					<v-icon >
						mdi-arrow-left
					</v-icon>
				</v-btn>
			</div>
		</div>

	</div>
	
	<!-- Modal de Negociação ----------------------- -->
	<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-carousel" role="document">
		<div class="modal-content" >
		<div class="modal-body p-0" style="margin-top:-75px;margin-bottom:-15px;min-height:650px;">
			<!-- <ModalNegociacao ref="ModalNegociacao"/> -->
		</div>
		</div>
	</div>
	</div>

  </div>  
</template>

<script type="text/javascript">

import {API, 
		API_BLOB,
       } from "../../services/API";

import "./MapaEmpreendimentos.css";
import "../ModalNegociacao/ModalNegociacao.css";

// -- Mapa -----------------------------------------------------------------------------------
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
//import OSM from 'ol/source/OSM'
import BingMaps from "ol/source/BingMaps.js";
import GeoJSON from "ol/format/GeoJSON";
import Overlay from 'ol/Overlay';
import OlImageLayer from "ol/layer/Image";
import ImageStatic from "ol/source/ImageStatic";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer.js";
import { Vector as VectorSource } from "ol/source.js";
import { defaults as DefaultControls, FullScreen, Control } from "ol/control";
import { DragRotateAndZoom, defaults as defaultInteractions } from "ol/interaction";
import Feature from 'ol/Feature';
import { Point, Circle, Polygon, RegularShape } from 'ol/geom';
import { Fill, Style, Stroke, Icon } from "ol/style.js";
import { fromLonLat, transformExtent } from "ol/proj.js";
import {altKeyOnly, click, pointerMove} from 'ol/events/condition';
import Select from "ol/interaction/Select.js";
import {easeIn, easeOut} from 'ol/easing';
import proj4 from "proj4";
import Geolocation from 'ol/Geolocation';

//import { Bounce, Pan } from "ol/animation";

import { formatNumber } from "../../services/funcoes";
import { EventBus } from "../../services/eventbus";

//import ModalNegociacao from "../ModalNegociacao/ModalNegociacao.vue";
import store_ModalNegociacao from "../ModalNegociacao/store_ModalNegociacao";
import store_usuario from "../../store/store_usuario";
import store_site from "../../store/store_site"
import store_Empreendimentos from "../Empreendimentos/store_Empreendimentos"

import pin_imagePath from './pin.png' 

var style_selected = new Style({ //style to be added on selected layer
	fill: new Fill({
		//color: "rgba(255, 0, 0, 0.5)",
		color: "rgba(0, 126, 255, 0.5)",
		opacity: 0.5
	}),
	stroke: new Stroke({
		//color: 'red',
		color: "rgba(0, 126, 255, 0.5)",
		width: 3,
		opacity:1
	})
})

export default {
	name: "MapaEmpreendimentos",

	components: {
		//ModalNegociacao,
	},
	data() {
		return {
			store_site			  : store_site,
			store_usuario		  : store_usuario,
     	store_ModalNegociacao : store_ModalNegociacao,
			store_Empreendimentos : store_Empreendimentos,

			formatNumber : formatNumber,

			map : null,
			view: null,
			geolocation: null,
			geo_JSON : null,
			pol_source1 : null,
			pol_vector1 : null,
			pol_source2 : null,
			pol_vector2 : null,
			pol_vectorAux : null,
			pol_sourceAux : null,

			mapEmp : {},
			ImgMapa : null,
			//height : this.getHeight(),
			imageBounds: {
				bottom: null,
				left: null,
				top: null,
				right: null,
			},			
			img_opacity: 1,
			imageSize: {
				width: null,
				height: null,
			},
			lat: null,
			lng: null,
			zoom: null,
			rotacao: null,
			pol_lote: 0,

			point_source1 : null,
			point_vector1 : null,

			botao_visible				: null,
			pol_selectPointerMove 		: null,
			pol_selectSingleClick 		: null,
			popup						: {},
			retorna_CodEmp_Popup		: 0,
		}

	},

 	beforeDestroy () {
		//  if (this.socket)
		// 	 	this.socket.disconnect();
	},
	 
 	async created () {

 	},

	computed: {
		store_EmpreendimentoPopup() {
			let _this = this;
			function ativa_popup() {
				_this.popup.element.hidden = false;
 				_this.popup.setPosition(fromLonLat([_this.store_Empreendimentos.empreend_selecionado_hover.empreend_mapa_latitude,
				 						            _this.store_Empreendimentos.empreend_selecionado_hover.empreend_mapa_longitude]));
				_this.retorna_CodEmp_Popup = _this.store_Empreendimentos.empreend_selecionado_hover.cod_empreendimento;
				return true
			}
			if (this.store_Empreendimentos.empreend_selecionado_hover
			    && this.store_Empreendimentos.empreend_selecionado_hover.cod_empreendimento > 0
				&& this.popup.element) {
				return ativa_popup();
			}
			else {
				return false;
			}
		},

	},

	async mounted () {
//		var _this = this;

// 		// Busca Dados do Empreendimento -------------------------------
// 		let resultado = await this.ByEmpreendimento();
// 		if (resultado) {
// console.log('mounted', resultado)
// 			let mapEmp = resultado.data.result;
// 			///+++ Quando for Lazarus
// 			this.imageBounds = {
// 				bottom: parseFloat(mapEmp.empreend_mapa_sw_bound_lng), // lat
// 				left: parseFloat(mapEmp.empreend_mapa_sw_bound_lat), // lng
// 				top: parseFloat(mapEmp.empreend_mapa_ne_bound_lng), // lat
// 				right: parseFloat(mapEmp.empreend_mapa_ne_bound_lat), // lng
// 			},
// 			///+++ Quando for NodeJS, lng e lat invertido
// 			// this.imageBounds = {
// 			// 	left: parseFloat(this.mapEmp.empreend_mapa_sw_bound_lng), // lat
// 			// 	bottom: parseFloat(this.mapEmp.empreend_mapa_sw_bound_lat), // lng
// 			// 	right: parseFloat(this.mapEmp.empreend_mapa_ne_bound_lng), // lat
// 			// 	top: parseFloat(this.mapEmp.empreend_mapa_ne_bound_lat), // lng
// 			// },
// 			this.img_opacity = 1,
// 			this.imageSize = {
// 				width: parseFloat(mapEmp.empreend_mapa_width),
// 				height: parseFloat(mapEmp.empreend_mapa_height),
// 			},

// 			///+++ Quando for Lazarus
// 			this.lat = parseFloat(mapEmp.empreend_mapa_longitude),
// 			this.lng = parseFloat(mapEmp.empreend_mapa_latitude),
// 			///+++ Quando for NodeJS, lng e lat invertido
// 			// this.lng = parseFloat(mapEmp.empreend_mapa_longitude),
// 			// this.lat = parseFloat(mapEmp.empreend_mapa_latitude),

// 			this.zoom = parseFloat(mapEmp.empreend_mapa_zoom)-2,
// 			this.rotacao = parseFloat(mapEmp.empreend_mapa_rotacao),
// 			this.pol_lote = 0
// 		}

		// -- Bing Layer -------------------------------------------------------------
		this.layer_Bing = new TileLayer({
			visible: true,
			preload: Infinity,
			source: new BingMaps({
				key: "AjqLNOOSzrQfPcNyK5safWtF7DI41xjmTF4Q7jUWyAAd-rX-Zu2Cn2HptKEJIte8",
				imagerySet: "AerialWithLabels",
				maxZoom: 19,
			}),
		});

		// -- Popup -------------------------------------------------------------------
		var element = document.getElementById('popup1');
		this.popup = new Overlay({
			positioning: 'center-center',
			element: element,
			stopEvent: false,
			offset: [0, -100],
		});
        this.popup.element.hidden = true;

		// -- Mapa --------------------------------------------------------------------
		this.view = new View({
			zoom: this.zoom,
			center: fromLonLat([this.lng, this.lat]),
		}),
 		this.map = new Map({
 			controls: DefaultControls().extend([new FullScreen()]),
 			interactions: defaultInteractions().extend([new DragRotateAndZoom()]),
 			target: this.$refs['map'],
 			view: this.view,
 			layers: [this.layer_Bing],
		});
		
		// Posição do Cliente no Mapa -----------------------------------------
		this.geolocation = new Geolocation({
			// enableHighAccuracy must be set to true to have the heading value.
			trackingOptions: {
				enableHighAccuracy: true,
			},
			projection: this.view.getProjection(),
		});
		
		this.map.addOverlay(this.popup);

// select interaction working on "pointermove"
// var selectPointerMove = new Select({
//   condition: pointerMove,
// });
//  this.map.addInteraction(selectPointerMove);
//     selectPointerMove.on('select', function (e) {
//     	if (e.selected.length > 0) {
// 	  		console.log(e.target.getFeatures());
// 		}
//         // e.target.getFeatures().getLength() +
//         // ' selected features (last operation selected ' +
//         // e.selected.length +
//         // ' and deselected ' +
//         // e.deselected.length +
//         // ' features)');
//     });

		if (store_Empreendimentos.array_cod_empreendimentos) {
//console.log('Empreendimentos - mounted - array_cod_empreendimentos', store_Empreendimentos.array_cod_empreendimentos)
        	await this.atualizaPoligonosDB(store_Empreendimentos.array_cod_empreendimentos);
		}

		this.map.on('click', (event) => {
			let layers = null;
			this.map.forEachFeatureAtPixel(event.pixel,
				(feature, layer) => {
					layers = layer;
					if (layer && layer.values_.cod_empreendimento) {
						this.store_Empreendimentos.empreend_selecionado_hover = { cod_empreendimento: parseInt(layer.values_.cod_empreendimento) }
					}
 				}
 			);
 			if (layers == null) {
				this.popup.element.hidden = true;
				if (JSON.stringify(this.store_Empreendimentos.empreend_selecionado_hover) != '{}') {
					this.store_Empreendimentos.empreend_selecionado_hover = {}
				}
 			}
 		})	
	},

	created () {
		
		// Foco nos poligons da coordenada --------
		EventBus.$on('flyTo', (coord, rotacao, zoom) => {
			this.flyTo(coord, rotacao, zoom);
		})

		// Visualização dos botões de acções em cima do mapa --------
		// EventBus.$on('mapa_botoes_selecao', (p_show) => {
		// 	this.botao_visible = p_show;
		// 	//console.log("mapa_botoes_selecao ---------")			
		// })

		EventBus.$on('MapaEmpreendimentos_AtualizaPoligonos', (array_cod_empreendimentos) => {
//console.log('MapaEmpreendimentos_AtualizaPoligonos', '---', array_cod_empreendimentos);
			// array_cod_empreendimentos = JSON.stringify(array_cod_empreendimentos)
			//                                      .replace('[', '')
			// 									 .replace(']', '');

			this.atualizaPoligonosDB(array_cod_empreendimentos)
			//this.flyTo(coord, rotacao, zoom);
		});
	},

	beforeDestroy() {
		// Parar de ouvir estes enventos, pode pode acumular na memária
		EventBus.$off('flyTo');
		EventBus.$off('MapaEmpreendimentos_AtualizaPoligonos');
	},

	methods : {
		VueRoute() {
			var ls_CodEmp = this.retorna_CodEmp_Popup;
			this.$router.push({ name: "EmpreendimentoDetalhe", params: {cod_empreendimento: ls_CodEmp} });
		},

		async addEventListener() {
			var _this = this;

			window.addEventListener("resize", function (e) {
				if (e) {
					e.stopImmediatePropagation();
					clearTimeout(window.updateSizeTimer);
					window.updateSizeTimer = setTimeout(async function () {
						var orgSize = _this.map.getSize();
						if (orgSize) {
							var newSize = [orgSize[0] + 100, await _this.getHeight() + 100];
							_this.map.setSize(newSize);
							_this.$refs['map'].style.height = await _this.getHeight() + "px";
							const view = await _this.map.getView();

							view.calculateExtent(_this.map.getSize());
							//map.getView().fit(extent, {size: newSize});
							//console.log(map.getTargetElement().style.height);
							//const li_Height = getHeight() + "px";
							//map.getTargetElement().style.height = li_Height;
							//map.setSize([map.getTargetElement().style.width + "px", li_Height]);
							//map.setSize([300, 400]);
						}
					}, 500);
				}
			})
		},

		async ByEmpreendimento(cod_empreendimento) {
//console.log('Empreendimentos - ByEmpreendimento', cod_empreendimento)
			const resposta = await API.get(`site/mapa_empreendimento`, {
			});
			if (resposta) {
				return resposta;
			}
		},

		async MapaByEmpreendimento(cod_empreendimento) {
//console.log('Empreendimentos - MapaByEmpreendimento', cod_empreendimento)
			const resposta = await API.get(`site/mapa_empreendimentos`, {
				params: {
					cod_empreendimento : cod_empreendimento,
				},
			});
			if (resposta) {
				return resposta;
			}
		},

		// async MapaImagemByEmpreendimento(cod_empreendimento) {
		// 	const resposta = await API_BLOB.get(`site/mapa_imagem`, {
		// 		params: {
		// 			cod_empreendimento : cod_empreendimento,
		// 		},
		// 	});
		// 	if (resposta) {
		// 		const file = new Blob([resposta.data], { type: "image/png" });
		// 		const url = URL.createObjectURL(file);
		// 		return url;
		// 	}
		// },

		drawStyle(feature) {
			var color = feature.values_.cor;
			return [
				new Style({
					fill: new Fill({
						color: this.rgba(color),
					}),
				}),
			];
		},

		async atualizaPoligonosDB(array_cod_empreendimentos) {
			await this.remove_Markers();
			await this.map.removeLayer(this.pol_vector1);

			if (array_cod_empreendimentos.length > 0) {
				const resultado = await this.MapaByEmpreendimento(array_cod_empreendimentos)
				if (resultado) {
					this.geo_JSON = resultado.data.result;
					this.pol_source2 = new VectorSource({
						projection: "EPSG:3857",
						features: new GeoJSON().readFeatures(this.geo_JSON, {
							featureProjection: "EPSG:3857",
						}),
						format: new GeoJSON({}),
					});
					this.pol_vector2 = new VectorLayer({
						source: this.pol_source2,
						//style: this.drawStyle,
						style: style_selected
					});
					await this.fitBounds(0);

					await this.map.addLayer(this.pol_vector2);
					this.pol_vector1 = this.pol_vector2;
					this.pol_vector2 = {};
					this.pol_source2 = {};

					// Adicionando Markers ---------------------------------------
					var ls_GEO = new GeoJSON().readFeatures(this.geo_JSON, {
							featureProjection: "EPSG:3857",
						});
					ls_GEO.forEach(element => {
						///+++ Quando for Lazarus
						this.desenha_Markers(element.values_.cod_empreendimento,
											element.values_.empreend_mapa_latitude,
											element.values_.empreend_mapa_longitude);
						///+++ Quando for NodeJS, lng e lat invertido
						//this.desenha_Markers(element.values_.empreend_mapa_longitude, element.values_.empreend_mapa_latitude);
					});
				}
			}
		},

		async atualizaPoligonos(data) {
			this.geo_JSON = data;
			this.pol_source2 = new VectorSource({
				projection: "EPSG:3857",
				features: new GeoJSON().readFeatures(this.geo_JSON, {
					featureProjection: "EPSG:3857",
				}),
				format: new GeoJSON({}),
			});
			this.pol_vector2 = new VectorLayer({
				source: this.pol_source2,
				style: this.drawStyle,
			});

			await this.map.removeLayer(this.pol_vector1);
			await this.map.addLayer(this.pol_vector2);
			this.pol_vector1 = this.pol_vector2;
			this.pol_vector2 = {};
			this.pol_source2 = {};

			//this.desenha_Markers();

		},

		async remove_Markers() {
			const layers = [...this.map.getLayers().getArray()]
			layers.forEach((layer) => {
					if (layer.values_.name == "point_vector")
						this.map.removeLayer(layer)
				}
			)
		},

		async desenha_Markers(cod_empreendimento, p_lng, p_lat) {
			var iconFeature = new Feature(new Point(fromLonLat([p_lng, p_lat])));
			var ls_Source = new VectorSource({
					features: [iconFeature],
				});
			var point_vector = new VectorLayer({
				source: ls_Source,
			})
			await this.map.addLayer(point_vector);
			point_vector.set('name', 'point_vector');
			point_vector.set('cod_empreendimento', cod_empreendimento);
			point_vector.setStyle(new Style({
				image: new Icon(({
					src: pin_imagePath,
					scale: 0.8,
				}))
			}))
			
		// this.pol_selectPointerMove = new Select(
		// 	{
		// 	target: [ls_Source],
		// 	condition: function (mapBrowserEvent) {
		// 		console.log('111111111111111111', mapBrowserEvent);
		// 		//return click(mapBrowserEvent) && altKeyOnly(mapBrowserEvent);
		// 	}
		// });
		// this.map.addInteraction(this.pol_selectPointerMove);


//     this.pol_selectSingleClick = new Select();
//     this.map.addInteraction(this.pol_selectSingleClick);
//     this.pol_selectSingleClick.on("select", (e) => {
//       const _this = this;
//       var _thisMap = this.map;
//       if (e.selected.length > 0) {
// 		  //e.target.getSource().getFeatures()[0].getGeometry().getCoordinates()
// console.log('2222222222', fromLonLat([p_lng, p_lat]));
//  						this.popup.element.innerHTML = "<div style='background-color:#FF0000'>TESTANDO</div>";
//  						this.popup.element.hidden = false;
//  						this.popup.setPosition(fromLonLat([p_lng, p_lat]));
// 	  }
// 	})

// this.map.on("click", function(evt) {
//     var coord = transformExtent(evt.coordinate, 'EPSG:4326', 'EPSG:3857');
//     var lon = coord[0];
//     var lat = coord[1];
//     console.log(evt);
//     console.log(lat);
// });

//  			var sourceEventListener = point_vector.on('pointerup', function(e) {
// // console.log('11111111111111', ls_Source.getState())
// 				console.log('GeoJSON loaded', e.target.values_.cod_empreendimento);
//  				if (ls_Source.getState() == 'ready') {
//  					ls_Source.un('pointerup', sourceEventListener);
//  				}
//  			});		

// var highlightCtrl = new SelectFeature([point_vector], 
// {
//   hover: true,
//   onSelect: function (e) { console.log("... process feature hover ...")  }, 
//   clickFeature: function (e) { console.log("clicked feature: " + e.id); } 
// });

		},

		getHeight() {
			return window.innerHeight - 64;
		},

		// transforma coordenadas
		transform(extent) {
			return transformExtent(extent, "EPSG:4326", "EPSG:3857");
		},

	    async busca_imovel (p_cod_imovel) {
			try {
				//const resp = await API.get("/imovel_sem_token", {
				const resp = await API.get("/imovel", {
				params: { 	cod_empresa : this.store_site.cod_empresa,
							cod_imovel  : p_cod_imovel
						}
				});
				const { data } = resp;
				if (data.message === "warning" || data.message === "error") {
					//console.log("data.message", data);
				}
				else {
					store_ModalNegociacao.dados = data.result[0];

				}
			}
			catch (err) {
				//console.log("err", err)
			}
		},

		handleOpenDialog (codImovel) {
			//this.props.getDialogoImovelVenda(codImovel);
			document.getElementById("mymodal").click();
			this.busca_imovel (codImovel)
	        this.$forceUpdate();
		},

		rgba(p_cor) {
		  return "rgba(" +
						(p_cor = p_cor.replace("#", ""))
							.match(new RegExp("(.{" + p_cor.length / 3 + "})", "g"))
							.map(function (l) {
								return parseInt(p_cor.length % 2 ? l + l : l, 16);
							})
							.concat(0.5 || 1)
							.join(",") +
						")"
		},

		async flyTo(p_location, p_rotacao, p_zoom) {

			let _view = this.map.getView();
			let _zoom = this.map.getView().getZoom();
			let duration = 1000;
            _view.constrainResolution = true;
			var done = function (p_done) {
				//console.log("p_done", p_done);
			}
			var parts = 3;
			var called = false;
			
			// animações ---------------------
			function callback(complete) {
				--parts;
				//console.log("parts", parts);
			 	if (called) {
			 		return;
			 	}
			 	if (parts === 0 || !complete) {
			 		called = true;
			 		_view.animate({
						rotation : p_rotacao,
						zoom     : p_zoom,
						duration : 200
					});
			 		done(complete);
				}
			}
			_view.animate(
				{
					center: p_location,
					duration: duration,
				},
				callback
			);
			_view.animate(
				{
					zoom: _zoom - 4,
					duration: duration / 2,
				},
				{
					zoom: _zoom,
					duration: duration / 2,
				},
				callback
			);
			_view.animate(
				{
			 		//rotation: p_rotacao + 2*Math.PI,
			 		anchor: p_location,
			 		easing: easeOut,
			 	},
				callback				 	
			)

			// Rotação ---------------------
			// _view.animate(
			// 	{
			// 		rotation: rotation + Math.PI,
			// 		anchor: location,
			// 		easing: easeIn,
			// 	},
			// 	{
			// 		rotation: rotation + 2 * Math.PI,
			// 		anchor: location,
			// 		easing: easeOut,
			// 	}			
			// )
			
			//_view.setRotation(rotacao);

		},

		fromLonLat (p_fromlonlat) {
			return fromLonLat(p_fromlonlat);
		},

		async fitBounds(p_rotacao) {
			await this.map.getView().setRotation(p_rotacao);
			var extent = new VectorSource({
				features: new GeoJSON().readFeatures(this.geo_JSON)
			}).getExtent()
			this.map.getView().fit(extent, this.map.getSize());
			await this.map.getView().setZoom(await this.map.getView().getZoom()-0.8);
//console.log(this.map.getView().targetCenter_[1]);
//console.log("this.BingMaps.getSource()", this.layer_Bing.getSource());
			let targetCenter_1 = (this.layer_Bing.getSource().maxZoom_ - this.map.getView().getZoom());
				targetCenter_1 = targetCenter_1 * targetCenter_1 * targetCenter_1 * targetCenter_1;
			this.map.getView().targetCenter_[1] = this.map.getView().targetCenter_[1] + targetCenter_1;
//console.log(this.layer_Bing.getSource().maxZoom_-this.map.getView().getZoom());
//console.log(targetCenter_1);
			await this.map.getView().setZoom(await this.map.getView().getZoom()-0.01);
		},
		
		mapa_botoes_selecao(p_show) {
			this.botao_visible = p_show;
		},

// 		abre_popup(p_CodEmp, p_Coord) {
// 			if (p_CodEmp 
// 			    && parseInt(p_CodEmp) > 0
// 			    //&& (!this.store_Empreendimentos.popupSelecao && p_CodEmp != this.store_Empreendimentos.popupSelecao)
// 				) {
// console.log(this.store_Empreendimentos.popupSelecao, parseInt(p_CodEmp), p_Coord)
// 					let ls_Aux = store_Empreendimentos.dados.find(o => o.cod_empreendimento == parseInt(p_CodEmp));
// 					this.store_EmpreendimentoPopup = ls_Aux;
// 					this.store_Empreendimentos.popupSelecao = parseInt(p_CodEmp);
// 					//this.popup.element.hidden = false;
// 					this.popup.setPosition(p_Coord);
// console.log('Abrindo', this.store_Empreendimentos.popupSelecao, parseInt(p_CodEmp), p_Coord);
// 			}
// 			else {
// 				//this.popup.element.hidden = true;
// 				this.store_Empreendimentos.popupSelecao = 0;
// 				this.store_EmpreendimentoPopup = {};
// 			}
// 		}

	},

}

</script>

<style scoped>
	*,

	html {
	box-sizing: border-box;
	list-style: none;
	font-size: 16px !important;
	text-decoration: none !important;
	}

	.page {
	padding: 0 20px;
	}

	.name {
		font-size: 14px !important;
		font-weight: 600 !important
	}

	/*-----------------------------------*/
	/*------ SEARCH AND FILTER ------*/
	/*-----------------------------------*/
	.search-filter-container {
	margin-top: -30px;
	}

	@media (max-width: 599px) {
	.search-filter-container .input-container {
		width: calc(100% - 10%);
		padding-right: 10px;
	}

	.search-filter-container .container-buttons {
		width: calc(100% - 90%);
		padding-left: 0px;
	}
	}

	.dropdown {
	background: #f5f5f5;
	border: 1px solid #ddd;
	outline: none;
	}

	.sorting::placeholder {
	color: #000;
	opacity: 0.5;
	}

	.content-filter {
	font-family: "Montserrat", sans-serif;
	font-weight: 600;
	font-size: 15px !important;
	}

	@media (max-width: 1024px) {
	.content-filter {
		margin-right: 8px;
	}
	}

	.active-view-btn {
	height: 30px !important;
	margin-left: 5px;
	background: #274abb !important;
	color: #fff !important;
	}

	@media (max-width: 1024px) {
	.active-view-btn {
		width: 45px !important;
		padding: 2px 15px !important;
		justify-content: center;
	}

	.active-view-btn .v-icon {
		margin-left: 0px;
	}
	}

	@media (max-width: 768px) {
	.active-view-btn {
		width: 70% !important;
	}
	.active-view-btn .v-icon {
		margin-left: 10px;
	}
	}

	@media (max-width: 599px) {
	.active-view-btn {
		width: 35px !important;
	}

	.active-view-btn .v-icon {
		width: 35px !important;
		margin-left: 0px;
	}
	}

	.active-view-btn:hover {
	background: #274abb !important;
	color: #fff;
	opacity: 0.9;
	}

	/* .toggle-btn.enabled {
	border: 1px inset #121b22;
	background: #121b22 !important;
	color: #fff;
	}

	.toggle-btn.enabled:hover,
	.toggle-btn:hover {
	background: #274abb !important;
	}

	.toggle-btn {
	width: 35px;
	border: 1px inset #ddd;
	padding: 6px 10px;
	color: #121b22 !important;
	} */

	.list {
	color: #fff;
	}

	.grid {
	color: #000;
	}

	/*-----------------------------------*/
	/*------ MODAL FILTER ------*/
	/*-----------------------------------*/
	.v-dialog {
	height: 680px !important;
	overflow-y: hidden !important;
	}

	.card-filter {
	overflow-y: hidden;
	}

	.modal-wrapper {
	height: 100%;
	background: #e8e8e8 !important;
	}

	.range-slider {
	width: 100%;
	margin: auto;
	text-align: center;
	position: relative;
	height: 6em;
	}

	.range-slider svg,
	.range-slider input[type="range"] {
	position: absolute;
	left: 0;
	bottom: 0;
	}

	input[type="number"] {
	border: 1px solid #ddd;
	text-align: center;
	font-size: 1.6em;
	-moz-appearance: textfield;
	}

	input[type="number"]::-webkit-outer-spin-button,
	input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	}

	input[type="number"]:invalid,
	input[type="number"]:out-of-range {
	border: 2px solid #ff6347;
	}

	input[type="range"] {
	-webkit-appearance: none;
	width: 100%;
	}

	input[type="range"]:focus {
	outline: none;
	}

	input[type="range"]:focus::-webkit-slider-runnable-track {
	background: #2497e3;
	}

	input[type="range"]:focus::-ms-fill-lower {
	background: #2497e3;
	}

	input[type="range"]:focus::-ms-fill-upper {
	background: #2497e3;
	}

	input[type="range"]::-webkit-slider-runnable-track {
	width: 100%;
	height: 5px;
	cursor: pointer;
	animation: 0.2s;
	background: #2497e3;
	border-radius: 1px;
	box-shadow: none;
	border: 0;
	}

	input[type="range"]::-webkit-slider-thumb {
	z-index: 2;
	position: relative;
	box-shadow: 0px 0px 0px #000;
	border: 1px solid #2497e3;
	height: 18px;
	width: 18px;
	border-radius: 25px;
	background: #a1d0ff;
	cursor: pointer;
	-webkit-appearance: none;
	margin-top: -7px;
	}

	input[type="range"] {
	background: #fff;
	padding: 10px;
	}

	select {
	padding: 9px;
	background: #fff;
	}

	@media (max-width: 375px) {
	.label-range {
		display: none;
	}

	.col-range-1,
	.col-range-3 {
		padding-right: 15px !important;
		padding-left: 0px !important;
	}

	.col-range-2,
	.col-range-4 {
		padding-right: 0px !important;
		padding-left: 15px !important;
	}
	}

	/*-----------------------------------*/
	/*------ CARD ------*/
	/*-----------------------------------*/
	@media (max-width: 1024px) {
	.card-column {
		width: 500px !important;
	}
	}

	@media (max-width: 599px) {
	.card-row {
		display: flex;
		flex-direction: column;
	}

	.col-avatar {
		padding-right: 16px !important;
	}
	}

	.card-title {
	font-size: 17px !important;
	font-weight: 600;
	text-transform: capitalize !important;
	color: #444;
	}

	.homes-tag.featured {
	height: 30px;
	font-weight: 600;
	background: #18ba60;
	color: #fff;
	}

	@media (max-width: 1024px) {
	.homes-tag span {
		display: none;
	}
	}

	@media (max-width: 768px) {
	.homes-tag span {
		display: inline-block;
		background: #18ba60;
	}
	}

	@media (max-width: 599px) {
	.homes-tag.featured {
		margin-bottom: 15px;
	}
	}

	.homes-tag.sale {
	width: 85px;
	height: 30px;
	font-weight: 600;
	background: #e54242;
	color: #fff;
	}

	.homes-img {
	/* height: 175px; */
	height: 100%;
	}

	@media (max-width: 599px) {
	.homes-img {
		margin-top: 30px !important;
	}
	}

	.footer {
	background: none;
	border-top: 1px solid #ebebeb;
	padding-top: 0.2rem;
	font-size: 13px;
	color: #666;
	}

	@media (max-width: 1024px) {
	.footer {
		flex-direction: column;
	}

	.footer-value {
		flex-direction: row !important;
	}

	.font-small {
		margin-right: 5px;
	}
	}

	@media (max-width: 768px) {
	.footer {
		flex-direction: row;
	}

	.footer-value {
		flex-direction: column !important;
	}
	}

	@media (max-width: 599px) {
	.footer {
		flex-direction: column;
	}

	.footer-value {
		flex-direction: row !important;
	}
	}

	.footer-value {
	font-family: "Lato", sans-serif;
	font-size: 17px;
	}

	.font-small {
	font-size: 15px !important;
	}

	/*-----------------------------------*/
	/*------ MAP ------*/
	/*-----------------------------------*/
	.map-fixed {
	position: fixed;
	top: 76px !important;
	right: 10px !important;
	}

	@media (max-width: 1024px) {
	.map-fixed {
		position: fixed;
		top: 76px !important;
		right: 10px !important;
	}
	}

	.v-card--reveal {
	align-items: center;
	bottom: 0;
	justify-content: center;
	opacity: .5;
	position: absolute;
	width: 100%;
	}

	/* -- Mapa --------------------------------------- */
	.ol-panel {
		position: absolute;
		background-color: white;
		filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
		padding: 15px;
		border-radius: 10px;
		border: 1px solid #cccccc;
		bottom: 12px;
		left: -50px;
		min-width: 100px;
	}

	#coordexterne {
		/* font-family: Verdana ; */
		font-size: 10px;
		text-align: right ;
		top: 15px;
		width: 80%;
		height: 50px ;
		left: 60px ;
		position: absolute ;
		background-color: transparent ;
		float: left;
		z-index: 999;
		
		display:flex;
		justify-content: center;
	}

	#popup1 {
        border-radius: 5px;
        border: 0px solid white;
        background-color: rgba(255, 255, 255, 1);
        padding: 1px;

		/* font-family: Verdana ; */
		font-size: 10px;
		text-align: right ;
		top: -120px;
		left: -140px ;
		width: 280px;
		height: 200px;		
		position: absolute ;
		float: left;
		z-index: 999;

		display:flex;
		justify-content: center;

    }

	.botoes {
        border-radius: 5px;
        border: 0px solid white;
        padding: 2px;

		/* font-family: Verdana ; */
		font-size: 10px;
		text-align: right ;

    }


</style>
