<template>
  <div  id="Empreendimentos"
        class="page"
        v-bind:style="{ 'background-color':COR_SECUNDARIA_2 }">

    <!-- <v-progress-linear indeterminate v-if="loading"></v-progress-linear> -->
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-row class="mb-3">
      <v-col lg="6" md="12" sm="12" class="card-column">
        <!-- Botao Filtro Avancado ------------------------------------>
        <v-col cols="12" class="container-filtro pl-1 pr-1" v-bind:style="{ 'background-color':COR_SECUNDARIA_2 }">
          <EmpreendimentoFiltroAvancado />
        </v-col>
        <!-- Fim Botao Filtro Avancado ------------------------------>

        <!-- Card -->
        <v-row
          v-for="(emp, index) of store_Empreendimentos.dados"
          v-bind:key="index"
          class="pl-0 mt-1"
        >
          <v-col lg="12" md="12" sm="12" xs="" class="column-card" outlined>
            <v-hover v-slot="{ hover }">
              <v-card
                :style="
                  store_Empreendimentos.styleSelecao == emp.cod_empreendimento
                    ? styleSelecionado
                    : styleNormal
                "
                :elevation="hover ? 3 : 1"
                :class="{ 'on-hover': hover }"
                class="mx-auto card"
                v-on:mouseover="SelecionaHover(emp.cod_empreendimento)"
                v-on:mouseleave="SelecionaHover()"
              >
                <v-card-text class="p-0" style="background-color: #fff">
                  <v-container class="p-0" style="background-color: #fff">
                    <v-row class="card-row">
                      <!-- Card area image -->
                      <v-col md="4" class="pr-0 px-2 py-2 col-avatar">
                        <!-- Card img -->
                        <div
                          v-if="emp.cod_empreendimento == 63"
                          style="margin-top: 0px; margin-bottom: 10px"
                        >
                          <span
                            class="
                              lancamento-tag
                              featured
                              caption
                              rounded
                              px-2
                              py-2
                            "
                          >
                            Lançamento !
                          </span>
                        </div>
                        <div style="margin-top: 0px; margin-left: -20px">
                          <span class="homes-tag featured caption rounded px-2 py-1" >
                            Disponíveis: {{ emp.quant_unidades }}</span>
                        </div>
                        <div style="background-color: #fff" class="ml-n6 py-1">
                          <a
                            href="#"
                            class="homes-img d-flex justify-center align-center"
                          >
                            <!---- Imagem do mapa ----------------->
                            <v-card height="140px" width="140px" elevation="0">
                              <v-avatar
                                class="profile"
                                color="white"
                                size="140"
                                tile
                              >
                                <v-img
                                  v-bind:src="emp.empreend_logo"
                                  max-width="100%"
                                  max-height="100%"
                                  contain
                                ></v-img>
                              </v-avatar>

                              <!-- <v-img
                                :src="
                                  ImagemCaminhoCompleto(emp.empreend_mapa_url)
                                "
                                :style="`transform: rotate(${getRadDeg(
                                  emp.empreend_mapa_rotacao
                                )}deg);`"
                                height="165px"
                                width="165px"
                              >
                              </v-img> -->
                            </v-card>
                          </a>
                        </div>
                      </v-col>
                      <!-- Fim card area image -->

                      <!-- Card area content -->
                      <v-col
                        class="col-content position-relative pt-4 pb-0"
                        md="8"
                      >
                        <!-- Address -->
                        <h3 class="name"
                            v-on:click="AbreDetalhe(emp.cod_empreendimento)"
                          >
                            <!-- <router-link
                            :to="{
                              name: 'EmpreendimentoDetalhe',
                              params: {
                                cod_empreendimento: emp.cod_empreendimento,
                              },
                            }"
                          > -->
                            {{ emp.empreend_nome }}
                            <!-- </router-link> -->
                   
                        </h3>
                        <p class="homes-address mb-3">
                          <v-icon class="mr-1 mb-1">mdi-map-marker</v-icon
                          ><span class="body-2"
                            >{{ emp.empreend_endereco }}
                            {{ emp.municipio }}</span
                          >
                        </p>
                        <!-- Card List -->
                        <ul class="homes-list clearfix mb-4 pl-0">
                          <li
                            v-for="detalhe in emp.detalhes"
                            :key="detalhe.descricao"
                          >
                            <span class="body-2">
                              <v-icon class="mr-2">{{ detalhe.icone }}</v-icon
                              >{{ detalhe.descricao }}
                            </span>
                          </li>
                        </ul>
                        <!-- Fim card List -->
                        <div class="footer d-flex justify-space-between pb-3">
                          <div class="footer-value d-flex flex-column">
                            <span class="font-small mt-2">Valores:</span>
                            <span class="font-valores"
                              >R$ {{ formatNumber(emp.preco_min) }} a R$
                              {{ formatNumber(emp.preco_max) }}
                            </span>
                            <span class="font-small mt-2">Área:</span>
                            <span class="font-valores"
                              >{{ formatNumber(emp.area_min) }}m² a
                              {{ formatNumber(emp.area_max) }}m²</span
                            >
                          </div>
                          <!-- <div class="footer-value d-flex flex-column">
                            <span class="font-small">Área:</span>
                            <span class="font-weight-black"
                              >{{ formatNumber(emp.area_min) }}m² a
                              {{ formatNumber(emp.area_max) }}m²</span
                            >
                          </div> -->
                        </div>
                      </v-col>

                      <!-- Seta -->
                      <div
                        v-if="
                          store_Empreendimentos.styleSelecao ==
                          emp.cod_empreendimento
                        "
                        class="seta"
                      >
                        <v-img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAjCAMAAACuNqptAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABVQTFRFJJfjdr/uweL3KZnkLJvk1uv6////VvnvPgAAAAd0Uk5T////////ABpLA0YAAABMSURBVHjaZNCxDcNAAMQw2Ulu/5HdGcFTJUt17awuCUtLS0tLS0tLS0tLS0tLgz4/5J4yZcqUKVOmvPS9z6t/MmXKlClTpixkjwADAKGPB6Bf3bAzAAAAAElFTkSuQmCC"
                        />
                      </div>
                      <!-- Fim Seta -->

                      <!-- Coluna dos botões com seleção ativa -->
                      <div
                        v-if="store_Empreendimentos.styleSelecao ==emp.cod_empreendimento"
                        class="container-action-icon"
                        style="visibility: visible;opacity: 1;background: rgba(196, 196, 196, 0.41);"
                      >
                        <v-btn
                          icon
                          color="rgba(0, 0, 0, 0.87)"
                          class="mt-4 mb-4 ml-n4 rounded-pill action-btn "
                        >
                          <v-icon class="action-icon-card mr-2">
                            mdi-magnify-plus </v-icon
                          ><span></span>
                        </v-btn>

                        <v-btn
                          icon
                          color="rgba(0, 0, 0, 0.87)"
                          class="mb-4 ml-n4 rounded-pill action-btn"
                          @click="dialogVideo = true"
                        >
                          <v-icon class="action-icon-card mr-2">
                            mdi-video-box</v-icon
                          ><span></span>
                        </v-btn>

                        <v-btn
                          icon
                          color="rgba(0, 0, 0, 0.87)"
                          class="mb-4 ml-n4 rounded-pill action-btn"
                          @click="store_Empreendimentos.fotos_galeria = emp.fotos;dialogGaleria = true;"
                        >
                          <v-icon class="action-icon-card mr-2">
                            mdi-image </v-icon
                          ><span></span>
                        </v-btn>

                        <v-btn
                          elevation="2"
                          icon
                          color="rgba(0, 0, 0, 0.87)"
                          class="mb-4 ml-n4 rounded-pill action-btn"
                        >
                          <router-link
                            :to="'/empreendimentodetalhe/' + emp.cod_empreendimento"
                          >
                            <v-icon class="action-icon-card mr-2">
                              mdi-map-marker </v-icon
                            ><span></span>
                          </router-link>
                        </v-btn>

                        <v-btn
                          elevation="2"
                          icon
                          class="mb-4 ml-n4 rounded-pill action-btn"
                        >
                          <router-link
                            :to="'/empreendimentoespelhovenda/' + emp.cod_empreendimento"
                          >
                          <v-flex text-center align-center>
                            <v-icon class="action-icon-card mr-2">
                              mdi-open-in-new </v-icon
                            ><span></span>
                          </v-flex>
                          </router-link>
                        </v-btn>

                      </div>
                      <!-- Fim Coluna dos botões com seleção -->

                      <!-- Coluna dos botões com hover -->
                      <div v-else class="container-action-icon">
                        <v-btn
                          elevation="0"
                          icon
                          class="mt-4 mb-4 ml-n4 rounded-pill action-btn text-center"
                          v-on:click="
                            flyTo(
                              fromLonLat([
                                emp.empreend_mapa_latitude,
                                emp.empreend_mapa_longitude,
                              ]),
                              emp.empreend_mapa_rotacao,
                              emp.empreend_mapa_zoom,
                              emp.cod_empreendimento
                            )
                          "
                        >
                          <v-flex text-center align-center>
                          <v-icon class="action-icon-card mr-2 text-center">
                            mdi-magnify-plus </v-icon
                          ><span></span>
                          </v-flex>
                        </v-btn>

                        <v-btn
                          v-if="emp.empreend_video_url"
                          elevation="0"
                          icon
                          color="rgba(0, 0, 255, 0.87)"
                          class="mb-4 ml-n4 rounded-pill action-btn"
                          @click="dialogVideo = true"
                        >
                          <v-flex text-center align-center>
                          <v-icon class="action-icon-card mr-2">
                            mdi-video-box</v-icon
                          ><span></span>
                        </v-flex>
                        </v-btn>

                        <v-btn
                          v-if="store_Empreendimentos.fotos_galeria"
                          elevation="0"
                          icon
                          class="mb-4 ml-n4 rounded-pill action-btn"
                          @click="store_Empreendimentos.fotos_galeria = emp.fotos; dialogGaleria = true;"
                        >
                          <v-flex text-center align-center>
                          <v-icon class="action-icon-card mr-2">
                            mdi-image </v-icon
                          ><span></span>
                          </v-flex>
                        </v-btn>
                        
                        <v-btn
                          elevation="0"
                          icon
                          class="mb-4 ml-n4 rounded-pill action-btn"
                        >
                          <router-link
                            :to="'/empreendimentodetalhe/' + emp.cod_empreendimento"
                          >
                          <v-flex text-center align-center>
                            <v-icon class="action-icon-card mr-2">
                              mdi-map-marker </v-icon
                            ><span></span>
                          </v-flex>
                          </router-link>
                        </v-btn>

                        <v-btn
                          elevation="0"
                          icon
                          class="mb-4 ml-n4 rounded-pill action-btn"
                        >
                          <router-link
                            :to="'/empreendimentoespelhovenda/' + emp.cod_empreendimento"
                          >
                          <v-flex text-center align-center>
                            <v-icon class="action-icon-card mr-2">
                              mdi-view-grid-outline </v-icon
                            ><span></span>
                          </v-flex>
                        </router-link>
                        </v-btn>
                        
                      </div>
                      <!-- Fim Coluna dos botões com hover -->

                      <!-- Dialog Video -->
                      <v-dialog 
                        v-if="emp.empreend_video_url"
                        v-model="dialogVideo"
                        width="600" 
                        :retain-focus="false">
                        <v-card 
                          v-if="emp.empreend_video_url"
                          class="mb-6 pt-5">
                          <v-card-title class="d-none"
                            ><h2 class="h5 font-weight-bold"></h2>
                          </v-card-title>

                          <div class="container-video">
                            <div class="panel-video">
                              <iframe class="content-video px-5 pb-5"
                                      :src="emp.empreend_video_url" frameborder="0"
                                      allow="autoplay; accelerometer; encrypted-media; gyroscope; fullscreen; picture-in-picture"
                                      allowfullscreen>
                              </iframe>
                            </div>
                          </div>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              text
                              @click="dialogVideo = false"
                            >
                              Fechar
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <!-- Fim Dialog Video -->

                      <!-- Dialog Galeria -->
                      <v-dialog 
                        v-if="store_Empreendimentos.fotos_galeria"
                        v-model="dialogGaleria"
                        width="800"
                        :retain-focus="false">
                        <v-card class="mb-6 pt-5">
                          <v-card-title class="d-none"
                            ><h2 class="h5 font-weight-bold">
                              Galeria de Imagens
                            </h2>
                          </v-card-title>
                          <EmpreendimentosGaleria />
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              text
                              @click="dialogGaleria = false"
                            >
                              Fechar
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <!-- Fim Dialog Galeria -->

                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-col>

      <v-col lg="6" md="6" class="map-fixed" v-bind:style="{ 'background-color':COR_SECUNDARIA_2 }">
        <MapaEmpreendimentos />
      </v-col>
    </v-row>
  </div>
</template>

<script type="text/javascript">
import { COR_PRINCIPAL, COR_BACKGROUND, COR_SECUNDARIA, COR_SECUNDARIA_2 } from "../../services/constantes";
// @ is an alias to /src
import { API, API_BLOB, baseURL } from "../../services/API";
// import "jquery-ui";
// import "jquery-ui-bundle";
//import "range-slider";
//import "bootstrap";
import MapaEmpreendimentos from "../EmpreendMapa/MapaEmpreendimentos.vue";
import EmpreendimentoFiltroAvancado from "./EmpreendimentoFiltroAvancado.vue";
import EmpreendimentosGaleria from "./EmpreendimentosGaleria.vue";
import { EventBus } from "../../services/eventbus";
import { fromLonLat, transformExtent } from "ol/proj.js";
import { formatNumber, getRadDeg } from "../../services/funcoes";

import store_site from "../../store/store_site";
import store_Empreendimentos from "../Empreendimentos/store_Empreendimentos";

export default {
  components: {
    MapaEmpreendimentos,
    EmpreendimentoFiltroAvancado,
    EmpreendimentosGaleria,
  },
  data() {
    return {
      store_site            : store_site,
      store_Empreendimentos : store_Empreendimentos,
      vm                    : store_Empreendimentos.vm,

      COR_PRINCIPAL         : COR_PRINCIPAL,
      COR_BACKGROUND        : COR_BACKGROUND,
      COR_SECUNDARIA        : COR_SECUNDARIA,
      COR_SECUNDARIA_2      : COR_SECUNDARIA_2,

      formatNumber          : formatNumber,
      getRadDeg             : getRadDeg,

      dialog                : false,
      dialogVideo           : false,
      dialogGaleria         : false,
      loading               : false,

      hover                 : false,
      styleNormal           : { background: "white", border: "3px solid white" },
      styleSelecionado      : { background: "white", border: "3px solid #2497e3" },

      objectUrl             : "",
      baseURL               : baseURL,
      rotation              : 0,
    };
  },

  created() {
    //moment.locale('pt-br');
    //  moment.defaultFormat = 'YYYY-MM-DDTHH:mm:ss.SSS-0300';
    //console.log("CREATED1", this.$moment('01/01/2019', 'DD/MM/YYYY').format());
  },

  mounted() {
    this.store_Empreendimentos.empreend_selecionado = {};
    this.loading = true;
    if (this.vm.vm_area_options.area_max == store_site.C_FILTROAVANCADO_VALORMAXIMO) {
      this.vm.vm_preco_options.preco_min = 0;
      this.vm.vm_preco_options.preco_max = 0;
      this.vm.vm_area_options.area_min = 0;
      this.vm.vm_area_options.area_max = 0;
      this.loading = this.store_Empreendimentos.filtra_empreendimento()
        .then(() => {
          this.loading = false
        })
    } else {
      this.loading = this.store_Empreendimentos.filtra_empreendimento({
        params: {
          filtro: this.vm.vm_filtro,
          localizacao: this.vm.vm_localizacao_selected,
          tipo: this.vm.vm_tipo_selected,
          status: this.vm.vm_status_selected,
          preco_min: this.vm.vm_preco_options.preco_min,
          preco_max: this.vm.vm_preco_options.preco_max,
          area_min: this.vm.vm_area_options.area_min,
          area_max: this.vm.vm_area_options.area_max,
          cod_empreendimento: this.vm.vm_cod_empreendimento,
        },
      })
      .then(() => {
        this.loading = false
      })
    }
  },

  methods: {
    ImagemCaminhoCompleto(img_url) {
      var img = baseURL + img_url;
      return img;
    },

    AbreDetalhe(cod_empreendimento) {
      API.post("/site/estatistica_busca", {
        filtro: JSON.stringify({ cod_empreendimento: cod_empreendimento }),
      });

      this.$router.push("empreendimentodetalhe/" + cod_empreendimento);
    },

    // async MapaImagemByEmpreendimento(cod_empreendimento) {
    //   const resposta = await API_BLOB.get(`site/mapa_imagem`, {
    //     params: {
    //       cod_empreendimento,
    //     },
    //   });
    //   if (resposta) {
    //     const file = new Blob([resposta.data], { type: "image/png" });
    //     const url = URL.createObjectURL(file);
    //     return url;
    //   }
    // },

    async flyTo(coord, rotacao, zoom, cod_Emp) {
      store_Empreendimentos.styleSelecao = cod_Emp;
      store_Empreendimentos.botao_visible = true;
      //store_Empreendimentos.empreend_selecionado = { cod_empreendimento: cod_Emp };
      await EventBus.$emit("flyTo", coord, rotacao, zoom);
      store_Empreendimentos.empreend_selecionado_hover = {
        cod_empreendimento: cod_Emp,
      };
    },

    fromLonLat(coord) {
      return fromLonLat(coord);
    },

    SelecionaHover(p_CodEmp) {
      if (p_CodEmp && !store_Empreendimentos.botao_visible) {
        if (
          !store_Empreendimentos.empreend_selecionado_hover
            .cod_empreendimento ||
          p_CodEmp !=
            store_Empreendimentos.empreend_selecionado_hover.cod_empreendimento
        ) {
          store_Empreendimentos.empreend_selecionado_hover = {
            cod_empreendimento: p_CodEmp,
          };
        }
      } else {
      }
    },
  },
};

Array.prototype.insert = function (index, item) {
  this.splice(index, 0, item);
};
</script>

<style scoped>
*,
html {
  box-sizing: border-box;
  list-style: none;
  text-decoration: none !important;
}

.page {
  padding: 0 20px;
  background-color: var(--COR_SECUNDARIA) !important;
}

/*-----------------------------------*/
/*------ FILTER ------*/
/*-----------------------------------*/
.container-filtro {
  position: sticky;
  width: 100% !important;
  top: 64px;
  /* Alterar padding-top para 4px se não quiser que levante ao do scroll */
  padding-top: 4px;
  padding-bottom: 0px;
  z-index: 2;
  margin-bottom: 0px;
  background: #E0E0E0;
}

@media (max-width: 1024px) {
  .container-filtro {
    padding: 0px !important;
  }
}

@media (max-width: 768px) {
  .container-filtro {
    top: 56px;
    padding: 12px !important;
  }
}

/*-----------------------------------*/
/*------ MODAL FILTER ------*/
/*-----------------------------------*/
.v-dialog {
  height: 680px !important;
  overflow-y: hidden !important;
}

.card-filter {
  overflow-y: hidden;
}

.modal-wrapper {
  height: 100%;
  background: #e8e8e8 !important;
}

.range-slider {
  width: 100%;
  margin: auto;
  text-align: center;
  position: relative;
  height: 6em;
}

.range-slider svg,
.range-slider input[type="range"] {
  position: absolute;
  left: 0;
  bottom: 0;
}

input[type="number"] {
  border: 1px solid #ddd;
  text-align: center;
  font-size: 1.6em;
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"]:invalid,
input[type="number"]:out-of-range {
  border: 2px solid #ff6347;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #2497e3;
}

input[type="range"]:focus::-ms-fill-lower {
  background: #2497e3;
}

input[type="range"]:focus::-ms-fill-upper {
  background: #2497e3;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animation: 0.2s;
  background: #2497e3;
  border-radius: 1px;
  box-shadow: none;
  border: 0;
}

input[type="range"]::-webkit-slider-thumb {
  z-index: 2;
  position: relative;
  box-shadow: 0px 0px 0px #000;
  border: 1px solid #2497e3;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #a1d0ff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}

input[type="range"] {
  background: #fff;
  padding: 10px;
}

select {
  padding: 9px;
  background: #fff;
}

/* @media (max-width: 375px) {
  .label-range {
    display: none;
  }

  .col-range-1,
  .col-range-3 {
    padding-right: 15px !important;
    padding-left: 0px !important;
  }

  .col-range-2,
  .col-range-4 {
    padding-right: 0px !important;
    padding-left: 15px !important;
  }
} */

.card-column {
  max-width: 500px !important;
  /* background-color: var(--COR_BACKGROUND) !important; */
}

/*-----------------------------------*/
/*------ CARD ------*/
/*-----------------------------------*/
/* @media (min-width: 1440px) {
  .card-column {
    max-width: 650px !important;
  }
}

@media (max-width: 1024px) {
  .card-column {
    width: 500px !important;
  }
} */

@media (max-width: 599px) {
  .card-row {
    display: flex;
    flex-direction: column;
  }

  .col-avatar {
    padding-right: 16px !important;
  }
}

.name {
  color: #2b80d5;
  cursor: pointer;
}

.card-title {
  font-size: 17px !important;
  font-weight: 600;
  text-transform: capitalize !important;
  color: #444;
}

.homes-tag.featured {
  height: 30px;
  font-weight: 600;
  background: #18ba60;
  color: #fff;
}

.lancamento-tag.featured {
  height: 30px;
  font-weight: 600;
  background: #ff0000;
  color: #fff;
}

.font-small {
    margin-right: 5px;
    color: #000000 !important;
    font-size: 15px !important;
  }

@media (max-width: 1024px) {
  .homes-tag span {
    display: none;
  }
}

@media (max-width: 768px) {
  .homes-tag span {
    display: inline-block;
    background: #18ba60;
  }
}

@media (max-width: 599px) {
  .homes-tag.featured {
    margin-bottom: 15px;
  }
}

.homes-img {
  height: 100%;
}

@media (max-width: 599px) {
  .homes-img {
    margin-top: 30px !important;
  }
}

.footer {
  background: none;
  border-top: 1px solid #ebebeb;
  padding-top: 0.2rem;
  font-size: 13px;
  color: #666;
}

@media (max-width: 1024px) {
  .footer {
    flex-direction: column;
  }

}

@media (max-width: 768px) {
  .footer {
    flex-direction: row;
  }
}

@media (max-width: 599px) {
  .footer {
    flex-direction: column;
  }
}

.footer-value {
  font-family: "Lato", sans-serif;
  font-size: 17px;
}

.seta {
  content: "";
  position: absolute;
  top: 90px;
  height: 35px;
  width: 18px;
  right: -18px;
}

.container-action-icon {
  width: 125px;
  height: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 26px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.5s linear;
  -moz-transition: opacity 0.5s linear;
  -o-transition: opacity 0.5s linear;
  transition: opacity 0.5s linear;
}

.action-icon-card {
  font-size: 23px !important;
  /* color: rgba(54, 105, 171, 0.87) !important; */
  color: #1976D2 !important;
  justify-content: center;
}

.card:hover .container-action-icon {
  visibility: visible;
  opacity: 1;
  background: rgba(196, 196, 196, 0.61);
}

.action-btn {
  width: 105px;
  background: #f8fcfe;
  height: 28px;
  align-content: center;
}

.action-btn span {
  width: 60px;
  font-size: 12px !important;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.87) !important;
}

@media (max-width: 768px) {
  .container-action-icon {
    width: 100%;
    position: relative;
    visibility: visible;
    justify-content: space-between;
    flex-wrap: wrap;
    opacity: 1;
    flex-direction: row;
    margin-top: 5px;
    padding-left: 40%;
    padding-right: 2%;
    background: transparent !important;
  }

  .action-btn:nth-child(1) {
    display: none;
  }
}

@media (max-width: 599px) {
  .container-action-icon {
    padding-left: 30px;
    padding-right: 15px;
  }

  .action-btn {
    width: initial;
    padding: 0px 10px;
  }

  .action-btn span {
    width: initial;
  }
}

@media (max-width: 375px) {
  .container-action-icon {
    width: 100%;
    position: relative;
    visibility: visible;
    justify-content: space-between;
    flex-wrap: wrap;
    opacity: 1;
    flex-direction: row;
    margin-top: 5px;
  }

  .action-btn {
    padding: 0px 5px;
  }

  .action-icon-card {
    margin-right: 0px !important;
  }

  .action-btn span {
    width: initial;
  }
}

/*-----------------------------------*/
/*------ MAP ------*/
/*-----------------------------------*/
.map-fixed {
  position: fixed;
  top: 53px !important;
  right: -10px !important;
  max-width: calc(100% - 495px) !important;
}

/* @media (min-width: 1440px) {
  .map-fixed {
    max-width: calc(100% - 550px) !important;
  }
} */

/* @media (max-width: 1024px) {
  .map-fixed {
    position: fixed;
    top: 76px !important;
    right: 10px !important;
  }
} */

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.4;
  position: relative;
  width: 100%;
  height: 100%;
}

#gallery {
  height: 165px;
  width: 165px;
  max-width: 165px;
  max-height: 165px;
  position: relative;
  background-color: #00ffff;
  align-self: center;
}

#gallery img {
  /* CSS Hack will make it width 100% and height 100% */
  position: absolute;
  opacity: 0.5;
  background-color: #00ff00;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  /* Maintain aspect ratio */
  max-height: 165px;
  max-width: 165px;
}

/*-----------------------------------*/
/*------ VIDEO ------*/
/*-----------------------------------*/
.container-video {
  padding: 0px !important;
}

.container-video {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.panel-video::before {
  padding-top: 56.25%;
}

.panel-video::before {
  display: block;
  content: "";
}

.container-video .content-video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

@media (max-width: 599px) {
  .v-card iframe {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

.font-valores {
  font-size: 16px !important;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.57) !important;
}

</style>
