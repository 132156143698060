<template>
  <div id="MapaLotes">
    <div
      id="conteudo_logado"
      ref="conteudo_logado"
      v-if="store_usuario.autenticado"
    >

      <v-row class="row-filter w-100" style="">
        <!-- Filtro Avançado ----------------------------------->
        <v-col class="">
          <div class="px-4" style="">
            <EmpreendimentoFiltroAvancado />
          </div>
        </v-col>
      </v-row>

      <v-row class="map-row-table">
        <!-- Grade ----------------------------------->
        <v-col cols="12" sm="5" md="6" lg="4" class="col-grid pt-0">
          <v-container class="pt-0 pb-6 pr-0 map-container-table">
            <div class="px-4 filter-mobile">
              <EmpreendimentoFiltroAvancado />
            </div>

            <v-layout column class="map-v-layout" style="height: 580px">
              <v-flex md7 style="height: 100%">
                <v-data-table
                  :headers="headers"
                  :items="grade_unidades"
                  :search="search"
                  disable-pagination
                  fixed-header
                  height="580px"
                  hide-default-footer
                  v-model="selectedUnidade"
                  class="elevation-2 map-table-desktop map-data-table"
                  mobile-breakpoint="0"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr
                        v-for="item in items"
                        :key="item.id"
                        :search="search"
                        @mouseover="selectItem(item)"
                        @mouseleave="unSelectItem()"
                        @click="handleOpenDialog(item)"
                      >
                        <td style="20%">
                          {{ item.quadra }}
                        </td>
                        <td style="width: 30%">{{ item.lote }}</td>
                        <td style="width: 30%">
                          {{ formatNumber(parseFloat(item.area)) }}
                        </td>
                        <td
                          v-if="store_usuario.autenticado"
                          style="width: 30%"
                          class="td-valor-mobile"
                        >
                          {{ formatNumber(parseFloat(item.valor)) }}
                        </td>
                        <td
                          v-if="!store_usuario.autenticado"
                          style="text-align: center; width: 40%"
                          class="td-valor-mobile"
                        >
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="icon-help"
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-help-circle-outline
                              </v-icon>
                            </template>
                            <span
                              >Somente usuários logados <br />conseguem
                              visualizar os valores.<br />Faça o seu login ou
                              cadastre-se.</span
                            >
                          </v-tooltip>
                        </td>
                        <!-- <td>
                                        <div v-if="item === selectedItem">
                                          <v-btn color="primary" >
                                            <v-icon class="mr-2 d-none d-sm-flex" color="blue darken-4">mdi-checkbox-marked</v-icon>
                                          </v-btn>
                                        </div>
                                      </td> -->
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>

                <v-data-table
                  v-model="selectedUnidade"
                  :headers="headers"
                  :items="grade_unidades"
                  :search="search"
                  disable-pagination
                  fixed-header
                  height="450px"
                  hide-default-footer
                  class="elevation-2 map-table-mobile map-data-table"
                  mobile-breakpoint="0"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr
                        v-for="item in items"
                        :key="item.id"
                        :search="search"
                        @mouseover="selectItem(item)"
                        @mouseleave="unSelectItem()"
                        @click="handleOpenDialog(item)"
                      >
                        <td style="width: 20%">
                          {{ item.quadra }}
                        </td>
                        <td style="width: 30%">{{ item.lote }}</td>
                        <td style="width: 30%">
                          {{ formatNumber(parseFloat(item.area)) }}
                        </td>
                        <td
                          v-if="store_usuario.autenticado"
                          style="width: 30%"
                          class="td-valor-mobile"
                        >
                          {{ formatNumber(parseFloat(item.valor)) }}
                        </td>
                        <td
                          v-if="!store_usuario.autenticado"
                          style="text-align: center; width: 40%"
                          class="td-valor-mobile"
                        >
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="icon-help"
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-help-circle-outline
                              </v-icon>
                            </template>
                            <span
                              >Somente usuários logados <br />conseguem
                              visualizar os valores.<br />Faça o seu login ou
                              cadastre-se.</span
                            >
                          </v-tooltip>
                        </td>
                        <!-- <td>
                                        <div v-if="item === selectedItem">
                                          <v-btn color="primary" >
                                            <v-icon class="mr-2 d-none d-sm-flex" color="blue darken-4">mdi-checkbox-marked</v-icon>
                                          </v-btn>
                                        </div>
                                      </td> -->
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-container>
        </v-col>

        <!-- Mapa ----------------------------------->
        <v-col
          cols="12"
          sm="7"
          md="6"
          lg="8"
          class="d-sm-flex pr-9 pl-0"
          ref="map_cols"
          id="map_cols"
        >
          <div
            class="map-hide-375 d-none"
            ref="map"
            id="map"
            v-bind:style="{
              marginLeft: '20px',
              top: '0px',
              left: '0px',
              width: '90%',
              height: '580px',
            }"
          ></div>

          <div
            class="map-show-375"
            ref="map"
            id="map"
            v-bind:style="{
              marginLeft: '18px',
              top: '-40px',
              left: '0px',
              width: '100%',
              height: '570px',
            }"
          >
          </div>
        </v-col>
      </v-row>

      <!-- Modal de Negociação ----------------------------------------------------------------------------->
      <!-- Botão Ativação Modal -->
      <v-btn
        color="primary"
        dark
        id="mymodal"
        class="d-none"
        @click="store_ModalNegociacao.dialog = true"
      >
        Open Dialog
      </v-btn>
      <!-- End Botão Ativação Modal -->

      <!-- NOVO MODAL USANDO VUETIFY -->
      <v-row justify="center">
        <v-dialog
          v-model="store_ModalNegociacao.dialog"
          max-width="440"
          content-class="custom-dialog"
          ref="modalnegociacao"
          :retain-focus="false"
          height="300px"
          persistent
        >
          <v-card class="justify-end align-center">
            <ModalNegociacao ref="ModalNegociacao" />
          </v-card>
        </v-dialog>
      </v-row>
      <!-- NOVO MODAL USANDO VUETIFY -->

    </div>

    <div id="conteudo_naologado" v-if="!store_usuario.autenticado">
      <v-row>
        <!-- Filtro Avançado ----------------------------------->
        <v-col cols="12" sm="7" md="6" lg="12" class="">
          <div class="px-4" style="height: 200px">
            <span
              >Somente usuários logados <br />conseguem visualizar os dados do
              empreendimento.<br />Faça o seu login ou cadastre-se.</span
            >
            <v-btn
              class="text-none"
              color="#E0E0E0"
              id="mymodal"
              @click="store_Login.dialog_flutuante = true"
            >
              Entrar
            </v-btn>

            <!-- NOVO MODAL USANDO VUETIFY -->
            <v-row justify="center">
              <v-dialog
                v-model="store_Login.dialog_flutuante"
                max-width="440"
                :retain-focus="false"
              >
                <v-card class="justify-end align-center">
                  <LoginContainerFlutuante ref="LoginContainerFlutuante" />
                </v-card>
              </v-dialog>
            </v-row>
            <!-- NOVO MODAL USANDO VUETIFY -->
          </div>
        </v-col>
      </v-row>
    </div>

    <!-- <v-progress-linear indeterminate v-if="loading"></v-progress-linear> -->
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>


  </div>
</template>

<script type="text/javascript">

import { API, BASE_URL_WEBSOCKET, API_BLOB } from "../../services/API";

import moment from "moment";

// import "./MapaEmpreendimentos.css";
import "../ModalNegociacao/ModalNegociacao.css";

// -- Mapa -----------------------------------------------------------------------------------
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import OSM from 'ol/source/OSM'
import BingMaps from "ol/source/BingMaps.js";
import GeoJSON from "ol/format/GeoJSON";
import OlImageLayer from "ol/layer/Image";
import ImageStatic from "ol/source/ImageStatic";
import Static from "ol/source/ImageStatic";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer.js";
import { Vector as VectorSource } from "ol/source.js";
import { defaults as DefaultControls, FullScreen } from "ol/control";
import Control from 'ol/control/Control';
import {
  DragRotateAndZoom,
  defaults as defaultInteractions,
} from "ol/interaction";
import {create as createCanvas, scale as scaleCanvas, toContext} from 'ol/render';

import { Fill, Style, Stroke } from "ol/style.js";
import { fromLonLat, transformExtent } from "ol/proj.js";
import Select from "ol/interaction/Select.js";
import { rotateProjection } from "./Mapa_Funcoes";

// import { socket } from "../../main";
import io from 'socket.io-client';

import EmpreendimentoFiltroAvancado from "../Empreendimentos/EmpreendimentoFiltroAvancado";
import { EventBus } from "../../services/eventbus";

import store_site from "../../store/store_site";
import store_usuario from "../../store/store_usuario";
import store_ModalNegociacao from "../ModalNegociacao/store_ModalNegociacao";
import ModalNegociacao from "../ModalNegociacao/ModalNegociacao.vue";
import store_EmpreendMapa from "./store_EmpreendMapa";
import store_Empreendimentos from "../Empreendimentos/store_Empreendimentos";
import store_Login from "../Login/store_Login";
import LoginContainerFlutuante from "../Login/LoginContainerFlutuante";
import { OpcaoVendaGet } from "../../services/negociacoes";
import { formatNumber, dataURLToBlob } from "../../services/funcoes";

var socket;

// Estilo das Unidades Disponiveis
var style_disponivel = new Style({
  fill: new Fill({
    color: "rgba(0, 255, 9, 0.5)",
  }),
});

// Estilo das Unidades Indisponiveis
var style_indisponivel = new Style({
  fill: new Fill({
    color: "rgba(255, 255, 255, 0)",
  }),
});

// Estilo Selecionado Vemelho
var style_selected = new Style({
  fill: new Fill({
    color: "rgba(0, 9, 255, 0.3)",
  }),
  stroke: new Stroke({
    color: "rgba(0, 9, 255, 1)",
    width: 2,
  }),
});

export default {
  name: "MapaLotes",

  components: {
    EmpreendimentoFiltroAvancado,
    ModalNegociacao,
    LoginContainerFlutuante,
  },
  data() {
    return {
      store_site: store_site,
      store_usuario: store_usuario,
      store_ModalNegociacao: store_ModalNegociacao,
      store_EmpreendMapa: store_EmpreendMapa,
      store_Empreendimentos: store_Empreendimentos,
      store_Login: store_Login,

      // -- Tabela Lateral de Unidades--------------------
      grade_unidades: [],
      search: "",
      quadra: "",
      lote: "",
      area: "",
      valor: "",
      pre_filtro_area: store_site.pre_filtro_area,
      pre_filtro_valor: store_site.pre_filtro_valor,
      selectedUnidade: [],
      //selectedQuadra    : null,
      //selectedLote      : null,
      //selectedArea      : null,
      //selectedValor     : null,
      lotes: store_EmpreendMapa.dados,
      selectedItem: false,

      result: {},
      map: null,
      geo_JSON: null,
      geo_JSON_Filtro: null,
      pol_source1: null,
      pol_vector1: null,
      pol_source2: null,
      pol_vector2: null,
      id: null,
      mapEmp: {},
      ImgMapa: null,
      imageBounds: {
        bottom: null,
        left: null,
        top: null,
        right: null,
      },
      img_opacity     : 1,
      imageSize       : {
                          width   : null,
                          height  : null,
                        },
      lat             : null,
      lng             : null,
      zoom            : null,
      rotacao         : null,
      pol_lote        : 0,
      lo_UnidadesAux  : [],
      lo_Legenda      : [],

      imagem_rotacao  : 0,

      // dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      mostra: false,

      overlay: false,

      formatNumber   : formatNumber,
    };
  },

  created() {
    this.id = this.$route.params.cod_empreendimento;

    EventBus.$on("MapaLotes_AtualizaPoligonos", (p_CodEmp) => {
      this.atualizaPoligonos(p_CodEmp);
    });

    //EventBus.$on("MapaLotes_FechaDialogo", () => {
      //console.log('MapaLotes_FechaDialogo - EMIT');
      //this.store_ModalNegociacao.dialog = false;
    //});
  },

  beforeDestroy() {
    // Parar de ouvir estes enventos, pode pode acumular na memária
    socket.disconnect();
    EventBus.$off("MapaLotes_AtualizaPoligonos");
    //EventBus.$off("MapaLotes_FechaDialogo");
  },

  async mounted() {
    var __this = this;

    // Se não selecionou nenhum empreendimento então retornar para a tela de empreendimentos
    if (!this.id) {
      this.$router.push("/");
    }
    // Socket -------------------------------------------
    //if (socket) {
      //if (socket.connected) {
      //  console.log("CONECTADO !!!!", __this.id);
      //  socket.emit("sala_mapa", __this.id);
      //}
      //else {
        socket = io(BASE_URL_WEBSOCKET, {  transports          : ['websocket'],
                                            secure              : false,
                                            reconnection        : true,
                                            rejectUnauthorized  : false,
                                            forceNew            : true
                                        }
                        );
        socket.on("connected", function(e) {
          // console.log("ON CONNECTED: ", __this.store_site.cod_empresa, __this.id);
          socket.emit("sala_mapa", __this.store_site.cod_empresa, __this.id);
        });
        socket.on("connect", function(e) {
          // console.log("ON CONNECT: ", __this.store_site.cod_empresa, __this.id);
          socket.emit("sala_mapa", __this.store_site.cod_empresa, __this.id);
        });
        socket.on("connection", function(e) {
          // console.log("ON CONNECTION: ", __this.store_site.cod_empresa, __this.id);
          socket.emit("sala_mapa", __this.store_site.cod_empresa, __this.id);
        });
        socket.on('reconnect', function(e) {
          // console.log("ON RECONNECT: ", __this.store_site.cod_empresa, __this.id);
          socket.emit("sala_mapa", __this.store_site.cod_empresa, __this.id);
        })
      //}

      // Callback de resposta de atualizações do Mapa -----
      socket.on("mapa", function(data)  {
        //console.log("mapa atualizou:---------------- ");
        if (JSON.parse(data).mapa) {
          __this.atualizaPoligonos(JSON.parse(data).mapa);
        }
      });
    //}
    //else {
    //  console.log('NAO SOCKETTTTTTTTTTTTTT')
    //}

    //inicializa_mapa();
  },

  methods: {
    async inicializa_mapa() {
      // Busca Dados do Empreendimento -------------------------------
      this.resultado = await this.ByEmpreendimento(this.id);
      if (this.resultado) {
        let mapEmp = this.resultado.data.result;
        this.lo_Legenda = JSON.parse(this.resultado.data.result.legenda);

        ///+++ Quando for Lazarus
        (this.imageBounds = {
          bottom: parseFloat(mapEmp.empreend_mapa_sw_bound_lng), // lat
          left: parseFloat(mapEmp.empreend_mapa_sw_bound_lat), // lng
          top: parseFloat(mapEmp.empreend_mapa_ne_bound_lng), // lat
          right: parseFloat(mapEmp.empreend_mapa_ne_bound_lat), // lng
        }),
          ///+++ Quando for NodeJS, lng e lat invertido
          // this.imageBounds = {
          // 	left: parseFloat(this.mapEmp.empreend_mapa_sw_bound_lng), // lat
          // 	bottom: parseFloat(this.mapEmp.empreend_mapa_sw_bound_lat), // lng
          // 	right: parseFloat(this.mapEmp.empreend_mapa_ne_bound_lng), // lat
          // 	top: parseFloat(this.mapEmp.empreend_mapa_ne_bound_lat), // lng
          // },

          (this.img_opacity = 1),
          (this.imageSize = {
            width: parseFloat(mapEmp.empreend_mapa_width),
            height: parseFloat(mapEmp.empreend_mapa_height),
          }),
          ///+++ Quando for Lazarus
          (this.lat = parseFloat(mapEmp.empreend_mapa_longitude)),
          (this.lng = parseFloat(mapEmp.empreend_mapa_latitude)),
          ///+++ Quando for NodeJS, lng e lat invertido
          // this.lng = parseFloat(mapEmp.empreend_mapa_longitude),
          // this.lat = parseFloat(mapEmp.empreend_mapa_latitude),

          (this.zoom = parseFloat(mapEmp.empreend_mapa_zoom)),
          (this.rotacao = parseFloat(mapEmp.empreend_mapa_rotacao)),
          (this.pol_lote = 0),
          (this.imagem_rotacao = parseFloat(mapEmp.empreend_imagem_rotacao));
      }

      // -- Bing Layer -------------------------------------------------------------
      this.layer_Bing = new TileLayer({
        visible: true,
        preload: Infinity,
        source: new BingMaps({
          key: "AjqLNOOSzrQfPcNyK5safWtF7DI41xjmTF4Q7jUWyAAd-rX-Zu2Cn2HptKEJIte8",
          imagerySet: "AerialWithLabels",
          maxZoom: 19,
        }),
      });
      // -- Bing Layer -------------------------------------------------------------
      this.layer_Bing2 = new TileLayer({
        visible: true,
        preload: Infinity,
        source: new BingMaps({
          key: "AjqLNOOSzrQfPcNyK5safWtF7DI41xjmTF4Q7jUWyAAd-rX-Zu2Cn2HptKEJIte8",
          imagerySet: "AerialWithLabels",
          maxZoom: 19,
        }),
      });


      // -- Mapa --------------------------------------------------------------------
      this.map = new Map({
        controls: DefaultControls().extend([new FullScreen()]),
        interactions: defaultInteractions().extend([new DragRotateAndZoom()]),
        target: this.$refs["map"],
        view: new View({
          zoom: this.zoom,
          center: fromLonLat([this.lng, this.lat]),
        }),
        layers: [this.layer_Bing],
      });

      // -- Loteamento -------------------------------------------------------------
      let resultado = await this.MapaImagemByEmpreendimento(this.id);
      let imageExtent = null;
      let projection = null;
      if (resultado) {
        projection = this.map.getView().getProjection();
        imageExtent = this.transform([
          this.imageBounds.left,
          this.imageBounds.bottom,
          this.imageBounds.right,
          this.imageBounds.top,
        ]);
        imageExtent = transformExtent(imageExtent, "EPSG:3857", projection);
        this.imageSource = new Static({
          url: resultado,
          crossOrigin: "",
          projection: rotateProjection(
            "EPSG:3857",
            (Math.PI / 2) * this.imagem_rotacao,
            imageExtent
          ),
          imageExtent: imageExtent,
          imageSmoothing: true,
          opacity: 0.5,
        });

        this.ImgMapa = new OlImageLayer({
          opacity: 1,
          // source: new ImageStatic({
          //   imageSize: [this.imageSize.width, this.imageSize.height],
          //   url: resultado,
          //   projection: "EPSG:3857", // pixel
          //   imageExtent: this.transform([
          //     this.imageBounds.left,
          //     this.imageBounds.bottom,
          //     this.imageBounds.right,
          //     this.imageBounds.top,
          //   ]),
          // }),
        });
        this.ImgMapa.setSource(this.imageSource);
        this.map.addLayer(this.ImgMapa);

        // Poligonos ----------------------------------------------------------------
        this.geo_JSON = {};
        this.pol_source1 = new VectorSource({
          projection: "EPSG:3857",
          format: new GeoJSON({}),
        });
        this.pol_vector1 = new VectorLayer({
          source: this.pol_source1,
          style: this.drawStyle,
        });
        this.map.addLayer(this.pol_vector1);
      }

      // Legendas -------------------------------------------
      var element = document.createElement('div');
      element.className = 'ol-control-panel ol-unselectable ol-control custom-control';
      element.innerHTML='<v-btn class="custom-control-botao">legenda</v-btn>';
      var div_popup = document.createElement('div');
      var ls_legendas = '';
      
      this.lo_Legenda.forEach((elem) => {
        ls_legendas = ls_legendas + '<span style="background-color:' + elem.situacao_venda_cor_html + '!important" class="dot"></span><span class="dot-2">' + elem.situacao_nome + '</span><br>'

      })
      div_popup.innerHTML=ls_legendas;
      div_popup.setAttribute("id", "div_popup");
      div_popup.setAttribute("class", "custom-control-popup-invisible");
      element.appendChild(div_popup);
      var controlPanel = new Control({
          element: element
      });
      var handlePOPUPVisible = function(e) {
        div_popup.setAttribute("class", "custom-control-popup-visible");
      };
      var handlePOPUPInVisible = function(e) {
        div_popup.setAttribute("class", "custom-control-popup-invisible");
      };      
      element.addEventListener('click', handlePOPUPVisible, false);
      element.addEventListener('mouseenter', handlePOPUPVisible, false);
      element.addEventListener('mouseleave', handlePOPUPInVisible, false);
      this.map.addControl(controlPanel);
      // ----------------------------------------------------


      // Exporta Mapa -------------------------------------------
      if (!store_site.isMobileDevice) {
        var element2 = document.createElement('div');
        element2.className = 'ol-control-panel ol-unselectable ol-control exporta-control';
        element2.innerHTML='<v-btn class="custom-control-botao"><v-icon class="mdi mdi-download"></v-icon></v-btn>';
        var controlPanel2 = new Control({
            element: element2
        });
        element2.addEventListener('click', this.exportHighResMap, false);
        this.map.addControl(controlPanel2);
      }
      // ----------------------------------------------------

      this.atualizaPoligonosDB();

      // Selecoes --------------------------------------------------
      this.pol_select = null; // ref to currently selected interaction
      this.pol_selectSingleClick = new Select();
      this.pol_select = this.pol_selectSingleClick;
      this.map.addInteraction(this.pol_select);
      this.pol_select.on("select", (e) => {
        const _this = this;
        var _thisMap = this.map;

        if (e.selected.length > 0) {
          this.handleOpenDialog(e.target.getFeatures().array_[0].values_);

          // Mudando a cor de borda do selecionado -----------------------------
          //var ls_Cor = this.rgba(e.target.getFeatures().getArray()[0].values_.cor)

          // Primeirament deseleciona todos
          var features = _this.pol_vector1.getSource().getFeatures();
          features.forEach((layer) => {
            layer.setStyle(_this.drawStyle(layer));
          });

          // Depois seleciona o que clicou
          e.target.getFeatures().getArray()[0].setStyle(style_selected);
          var feature_selecionado = e.target.getFeatures().array_[0];
          //feature_selecionado.getStyle().getFill().setColor(ls_Cor)

          // Deixa clicar na mesma unidade que já está selecionada -------------
          _thisMap.on("click", function (evt) {
            displayFeatureInfo(evt.pixel);
          });
          var displayFeatureInfo = function (pixel) {
            var features = null;
            _thisMap.forEachFeatureAtPixel(pixel, function (feature) {
              features = feature;
              feature = null;
            });
            if (features && features == feature_selecionado) {
                _this.abre_imovel(feature_selecionado.values_.cod_imovel);
                //document.getElementById("mymodal").click();
              //}
            }
          };

          // Se quiser limpar o selecionado, retirar este comentário
          //e.target.getFeatures().clear();
        }
      });

      this.map.getView().setRotation(this.rotacao);
      this.map.once("rendercomplete", function () {
        //console.log('rendercomplete');
      });
      await this.addEventListener();
    },

    async addEventListener() {
      var _this = this;

      window.addEventListener("resiz", function (e) {
        if (e) {
          e.stopImmediatePropagation();
          clearTimeout(window.updateSizeTimer);
          window.updateSizeTimer = setTimeout(async function () {
            var orgSize = _this.map.getSize();
            if (orgSize) {
              var newSize = [orgSize[0] + 100, (await _this.getHeight()) + 100];
              _this.map.setSize(newSize);
              _this.$refs["map"].style.height =
                (await _this.getHeight()) + "px";
              const view = await _this.map.getView();

              view.calculateExtent(_this.map.getSize());
              //map.getView().fit(extent, {size: newSize});
              //console.log(map.getTargetElement().style.height);
              //const li_Height = getHeight() + "px";
              //map.getTargetElement().style.height = li_Height;
              //map.setSize([map.getTargetElement().style.width + "px", li_Height]);
              //map.setSize([300, 400]);
              //console.log(_this.map.getSize());
            }
          }, 500);
        }
      });
    },

    async ByEmpreendimento(cod_empreendimento) {
      //console.log('MapaLotes - ByEmpreendimento')
      const resposta = await API.get(`site/mapa_empreendimento`, {
        params: {
          cod_empreendimento,
        },
      });
      if (resposta) {
        return resposta;
      }
    },

    async MapaByEmpreendimento(cod_empreendimento) {
      const resposta = await API.get(`site/mapa_json`, {
        params: {
          cod_empreendimento: cod_empreendimento,
        },
      });
      if (resposta) {
        return resposta;
      }
    },

    async MapaImagemByEmpreendimento(cod_empreendimento) {
      const resposta = await API_BLOB.get(`site/mapa_imagem`, {
        params: {
          cod_empreendimento,
        },
      });
      if (resposta) {
        const file = new Blob([resposta.data], { type: "image/png" });
        const url = URL.createObjectURL(file);
        return url;
      }
    },

    drawStyle(feature) {
      // if (
      //   feature.values_.tipo_cor_usuario &&
      //   feature.values_.tipo_cor_usuario == "D"
      // ) {
      //   return [style_disponivel];
      // } else if (
      //   feature.values_.tipo_cor_usuario &&
      //   feature.values_.tipo_cor_usuario == "I"
      // ) {
      //   return [style_indisponivel];
      // } else {
      return [
        new Style({
          fill: new Fill({
            color: this.rgba(feature.values_.cor),
            opacity: 0.5,
          }),
          stroke: new Stroke({
            color: this.rgba(feature.values_.cor),
            width: 1,
            opacity: 1,
          }),
        }),
      ];
      // }
    },

    async atualizaPoligonosDB() {
      var _this = this;
      const resultado = await this.MapaByEmpreendimento(this.id);
      if (resultado) {
        _this.geo_JSON = resultado.data.result;
        _this.geo_JSON_Filtro = new GeoJSON().readFeatures(_this.geo_JSON, {
          featureProjection: "EPSG:3857",
        });
        _this.grade_unidades = await _this.Filtra_Unidades(
          _this.geo_JSON_Filtro
        );

        _this.pol_source2 = new VectorSource({
          projection: "EPSG:3857",
          features: _this.geo_JSON_Filtro,
          format: new GeoJSON({}),
        });
        _this.pol_vector2 = new VectorLayer({
          source: _this.pol_source2,
          style: _this.drawStyle,
        });
      }
      await _this.map.removeLayer(_this.pol_vector1);
      await _this.map.addLayer(_this.pol_vector2);
      _this.pol_vector1 = this.pol_vector2;
      _this.pol_vector2 = {};
      _this.pol_source2 = {};
      this.fitBounds();
    },

    async atualizaPoligonos(data) {
      var _this = this;
      if (data)
        _this.geo_JSON = data;
      _this.geo_JSON_Filtro = new GeoJSON().readFeatures(_this.geo_JSON, {
        featureProjection: "EPSG:3857",
      });
      _this.grade_unidades = await _this.Filtra_Unidades(_this.geo_JSON_Filtro);
      await _this.map.removeLayer(_this.pol_vector1);
      if (_this.grade_unidades.length > 0) {
        _this.pol_source2 = new VectorSource({
          projection: "EPSG:3857",
          features: _this.geo_JSON_Filtro,
          format: new GeoJSON({}),
        });
        _this.pol_vector2 = new VectorLayer({
          source: _this.pol_source2,
          style: _this.drawStyle,
        });
        await _this.map.addLayer(_this.pol_vector2);
        _this.pol_vector1 = _this.pol_vector2;
        _this.pol_vector2 = {};
        _this.pol_source2 = {};
      }
    },

    // Monta Unidades que serão mostradas no mapa, com o filtro de area e valor
    async Filtra_Unidades(p_GEO) {
      var _this = this;
      var lo_UnidadesAux = [];
      var lo_quadra_lotes = [];
      var quadras = [];
      var lotes = [];

      await p_GEO.forEach((element) => {

        // Verifica se deve retirar o lote da listagem por não estar disponível
        if (
          parseFloat(element.values_.metragem) >=
            _this.store_Empreendimentos.vm.vm_area_options.area_min &&
          parseFloat(element.values_.metragem) <=
            _this.store_Empreendimentos.vm.vm_area_options.area_max &&
          parseFloat(element.values_.valor) >=
            _this.store_Empreendimentos.vm.vm_preco_options.preco_min &&
          parseFloat(element.values_.valor) <=
            _this.store_Empreendimentos.vm.vm_preco_options.preco_max &&
          (_this.store_Empreendimentos.vm.vm_quadra != "Indiferente"
            ? element.values_.quadra == _this.store_Empreendimentos.vm.vm_quadra
            : true) &&
          (_this.store_Empreendimentos.vm.vm_lote != "Indiferente"
            ? element.values_.lote == _this.store_Empreendimentos.vm.vm_lote
            : true)
        ) {

          // Quando o usuário comum (corretor) usou o filtro avancado, não deixar aparecer lotes que não estejam disponíveis
          if (this.store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5 || o.cod_funcao == 6))) {
            element.values_.tipo_cor_usuario = "D"
          }
          else {
            if (_this.store_Empreendimentos.usou_filtro_avancado) {
              if (element.values_.situacao_sistema == 'DISPONIVEL') {
                element.values_.tipo_cor_usuario = "D"
              }
            }
            else
              element.values_.tipo_cor_usuario = "D"
          }

          lo_UnidadesAux.push({
            value: false,
            cod_imovel: element.values_.cod_imovel,
            quadra: element.values_.quadra,
            lote: element.values_.lote,
            area: element.values_.metragem,
            valor: element.values_.valor,
            situacao_sistema: element.values_.situacao_sistema,
            //disponivel: true,
          });
        } else {
          element.values_.tipo_cor_usuario = "I";
          //  //element.setStyle(style_disable);
        }

        // Alimenta o array de Quadras e Lotes do Empreendimento Selecionado
        lo_quadra_lotes.push({
          quadra: element.values_.quadra,
          lote: element.values_.lote,
        });
        quadras = [
          "Indiferente",
          ...new Set(lo_quadra_lotes.map((item) => item.quadra)),
        ];
        lotes = [
          "Indiferente",
          ...new Set(lo_quadra_lotes.map((item) => item.lote)),
        ];
      });

      //Deixando somente os disponiveis para a grade
      lo_UnidadesAux = lo_UnidadesAux.filter(
        (item) => item.situacao_sistema == "DISPONIVEL"
      );

      // Deixando somente os filtrados e apagando os outros do GEO filtrado
      var ls_GEOAux = p_GEO;
      _this.geo_JSON_Filtro = ls_GEOAux.filter(
        (item) => item.values_.tipo_cor_usuario == "D"
      );

// Atribuindo Quadra_Lotes como Observers no Emprendimento Selecionado
      this.$set(this.store_Empreendimentos.empreend_selecionado, "quadras", {
        ...quadras,
      });
      this.$set(this.store_Empreendimentos.empreend_selecionado, "lotes", {
        ...lotes,
      });

      // Contando totais de Lotes Disponiveis Encontrados
      var array_filtra_empreendimento_total_encontrado =
        await lo_UnidadesAux.map(function () {
          return 1;
        });
      this.store_Empreendimentos.filtra_empreendimento_total_encontrado =
        array_filtra_empreendimento_total_encontrado.reduce((a, b) => a + b, 0);

      return lo_UnidadesAux;
    },

    getHeight(p_element) {
      //console.log(document.getElementById(p_element).offsetHeight);
      return window.innerHeight - 64;
    },

    // transforma coordenadas
    transform(extent) {
      return transformExtent(extent, "EPSG:4326", "EPSG:3857");
    },

    async abre_imovel(p_cod_imovel) {
      try {
        //if (feature_selecionado.values_.situacao_sistema == "DISPONIVEL" ||
        //     this.store_usuario.user.pessoa_funcao.find(o => (o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5))) {

        this.store_ModalNegociacao.dados = {};

        // Buscando botoes habilitados deste imovel -------------------------
        this.store_ModalNegociacao.botoes = [];
        this.store_ModalNegociacao.botoes = await OpcaoVendaGet(p_cod_imovel);
        if (this.store_ModalNegociacao.botoes == "Não possui dados") {
          this.store_ModalNegociacao.botoes = [];
        }
        else {
          // Buscando Imovel -----------------------------------------
          const resp = await API.get("/imovel", {
            params: {
              cod_empresa : this.store_site.cod_empresa,
              cod_imovel  : p_cod_imovel,
            },
          });
          const { data } = resp;
          if (data.message === "warning" || data.message === "error") {
            console.log('======= ERRO BUSCA IMOVEL ======== ',  data)
          }
          else {
            //console.log('BUSCA IMOVEL -------- ',  data.result[0])
            this.store_ModalNegociacao.step = "ModalNegociar";
            this.store_ModalNegociacao.dados = data.result[0];
            this.store_ModalNegociacao.dados.preco_final    = this.store_ModalNegociacao.dados.preco_total;
            this.store_ModalNegociacao.dados.preco_desconto = 0;
            this.$nextTick(() => {
              //console.log('ssssssssss 1', this.store_ModalNegociacao.dados.preco_total, this.store_ModalNegociacao.dados)
              this.store_ModalNegociacao.dialog = true;
            });
          }
        }
      } catch (err) {
        //console.log("err", err);
      }
    },

    handleOpenDialog(unidade) {
      //if (unidade.situacao_sistema == "DISPONIVEL" ||
      //    this.store_usuario.user.pessoa_funcao.find((o) => o.cod_funcao == 2 || o.cod_funcao == 4 || o.cod_funcao == 5)) {
        //this.dialog = true;
        this.abre_imovel(unidade.cod_imovel);
        //document.getElementById("mymodal").click();
        //this.$forceUpdate();
        //console.log('ZZZZZZZZZZZZ', this.$refs["modalnegociacao"])
        //this.$nextTick(() => {
        //  this.$refs["map"].refresh();
        //});
        //this.$refs["modalnegociacao"]
      //}
    },

    rgba(p_cor) {
      return (
        "rgba(" +
        (p_cor = p_cor.replace("#", ""))
          .match(new RegExp("(.{" + p_cor.length / 3 + "})", "g"))
          .map(function (l) {
            return parseInt(p_cor.length % 2 ? l + l : l, 16);
          })
          .concat(0.5 || 1)
          .join(",") +
        ")"
      );
    },

    fitBounds() {
      var extent = new VectorSource({
        features: new GeoJSON().readFeatures(this.geo_JSON),
      }).getExtent();
      this.map.getView().fit(extent, this.map.getSize());

      // 			const projection = this.map.getView().getProjection();
      //       var extent = new VectorSource({
      //         features: new GeoJSON().readFeatures(this.geo_JSON),
      //       }).getExtent();
      //       let imageExtent = this.transform(extent);
      //       imageExtent = transformExtent(imageExtent, 'EPSG:3857', projection);
      // console.log("imageExtent", imageExtent);
      // console.log("this.map.getSize()", this.map.getSize());
      //       this.map.getView().fit(imageExtent, this.map.getSize());
    },

    // Coloca o foco do lote no MouseHover
    selectItem(item) {
      function isFind(layer) {
        return layer.values_.cod_imovel === item.cod_imovel;
      }

      this.selectedItem = item;
      var features = this.pol_vector1.getSource().getFeatures();
      features.forEach((layer) => {
        layer.setStyle(this.drawStyle(layer));
      });
      features.find(isFind).setStyle(style_selected);
    },

    // Retira o foco do lote no MouseHover
    unSelectItem() {
      this.selectedItem = false;
      var features = this.pol_vector1.getSource().getFeatures();
      features.forEach((layer) => {
        layer.setStyle(this.drawStyle(layer));
      });
      // if (layer.get('name') && layer.get('name') == 'flag_vectorLayer'){
      //   if (layer.values_.cod_imovel = item.cod_imovel)
      //     map.removeLayer(layer)
      //   console.log(layer);
      // }
    },
    
    async exportHighResMap() {
      var _this = this;
      _this.store_site.overlay_global = true;
      const exportOptions = {
        width: 3000, // Largura do canvas em pixels
        height: 1000, // Altura do canvas em pixels
        resolution: 1 // Fator de resolução
      };

      const size = _this.map.getSize();
      const viewResolution = _this.map.getView().getResolution();
      const viewCenter = _this.map.getView().getCenter();
      const viewRotation = _this.map.getView().getRotation();
      // Calcule a nova resolução
      //const width = size[0];
      //const height = size[1];
      const width = parseInt(size[0] * 4);
      const height = parseInt(size[1] * 4);
      const resolution = viewResolution * (size[0] / width);

      // Crie o canvas virtual
      const exportCanvas = document.createElement('canvas');
      exportCanvas.width = width * exportOptions.resolution;
      exportCanvas.height = height * exportOptions.resolution;
      const exportContext = exportCanvas.getContext('2d');

      // Ajuste o pixel ratio do contexto
      exportContext.scale(exportOptions.resolution, exportOptions.resolution);

      // Crie um mapa virtual para renderizar a alta resolução
      const exportMap = new Map({
        target: document.createElement('div'), // Cria um alvo fora da tela
        layers: [_this.layer_Bing2],
        view: new View({
          center: viewCenter,
          resolution: resolution,
          rotation: viewRotation
        })
      });

      // Imagem do Mapa -----------------------------------------------------------
      exportMap.addLayer(_this.ImgMapa);

      // Poligonos ----------------------------------------------------------------
      await exportMap.addLayer(_this.pol_vector1);

      exportMap.setSize([width, height]);
      // Ajustar a visualização do mapa virtual ao tamanho do canvas
      const extent = _this.map.getView().calculateExtent(_this.map.getSize());
      exportMap.getView().fit(extent, { size: [width, height], constrainResolution: false });

      // Renderizar as camadas do mapa no contexto do canvas
      exportMap.once('rendercomplete', function() {
        // Copia o conteúdo do canvas do exportMap para o exportCanvas
        const exportMapCanvas = exportMap.getViewport().querySelector('canvas');
        if (exportMapCanvas) {
          exportContext.drawImage(exportMapCanvas, 0, 0);
          
          const qualidade = 0.8; // Qualidade de compressão (entre 0 e 1)
          const dataURL = exportCanvas.toDataURL('image/jpeg', qualidade);
          const blob = dataURLToBlob(dataURL);
          // Criar um link temporário para download
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = 'high-res-map.jpeg';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }

        // Limpe o mapa virtual
        exportMap.setTarget(null);
        _this.store_site.overlay_global = false;
      });

      // Inicie a renderização do mapa virtual
      exportMap.renderSync();

    }

},

  // ---------------------------------------------------------------------------------------------
  computed: {
    //  lotes() {
    //    console.log("lotes", this.store_EmpreendMapa.dados)
    //    return this.store_EmpreendMapa.dados;
    //  },

    //this.$forceUpdate();
    logado() {
      //console.log("dialog_flutuante", this.store_Login.dialog_flutuante);
      return this.store_Login.dialog_flutuante;
    },

    headers() {
      var ret = [
        {
          text: "Quadra",
          value: "quadra",
          align: "center",
          // filter: value => {
          //   return true;
          // }
        },
        {
          text: "Lote",
          value: "lote",
          align: "center",
          // filter: value => {
          //   return true;
          // }
        },
        {
          text: "Área",
          value: "area",
          align: "center",
          // filter: value => {
          //   return (value >= parseFloat(this.store_Empreendimentos.vm.vm_area_options.area_min)) && (value <= parseFloat(this.store_Empreendimentos.vm.vm_area_options.area_max));
          // }
        },
        {
          text: "Valor",
          value: "valor",
          align: "center",
        //   // filter: value => {
        //   //   return (value >= parseFloat(this.store_Empreendimentos.vm.vm_preco_options.preco_min)) && (value <= parseFloat(this.store_Empreendimentos.vm.vm_preco_options.preco_max));
        //   // }
        },
      ];
      this.inicializa_mapa();
      return ret;
    },
  },
};
</script>

<style lang="scss">
$dialog-margin: 15px !important;
$dm: 15px !important;

@media (max-width: 375px) {
  .v-dialog,
  .v-dialog--active {
    // margin: (--dialog-margin);
    margin: $dm !important;
    // $dialog-margin: 15px !important;
  }
}

.modal-container-card {
  background: #e8e8e8 !important;
}

.map-table-mobile {
  display: none;
}

@media (max-width: 599px) {
  .map-row-table {
    display: flex;
    flex-direction: column-reverse;
  }

  .col-grid {
    margin-top: -60px !important;
  }

  .map-v-layout {
    height: 450px !important;
  }

  .map-table-desktop {
    display: none;
  }

  .map-table-mobile {
    display: block;
    height: 0px !important;
  }
}

.filter-mobile {
  display: none;
}

@media (max-width: 599px) {
  .filter-mobile {
    display: block;
  }

  .row-filter {
    display: none;
  }

  .map-container-table {
    margin-top: 100px;
    padding-right: 8px !important;
    padding-left: 8px !important;
    padding-bottom: 0px !important;
  }

  #map_cols {
    margin-top: 30px;
  }
}

.map-data-table {
  tr:hover {
    background-color: #b2c8ff !important;
  }
}

@media (max-width: 768px) {
  .td-valor-mobile {
    padding-right: 6px !important;
  }
}
@media (max-width: 599px) {
  .td-valor-mobile {
    padding-right: 16px !important;
  }
}

.icon-help {
  font-size: 1.4rem !important;
}

@media (max-width: 375px) {
  .map-hide-375 {
    display: none;
  }

  .map-show-375 {
    display: block;
  }
}

/* Modal General ------------------ */
.custom-dialog.v-dialog {
  margin: 12px;
}

/* ========================= MAPA CONTAINER =========================== */
.mapa-container {
  margin: 10px;
	display: flex;
	flex-wrap: wrap;
}

.content {
  min-width: 100% !important;
  height: 100%;
}

.scroll {
  height: 100%;
}

#map {
  width: 100%;
  height: 100%;
  border: none; 
  // position: absolute;
  flex: 1;
}
   
// .ol-viewport {
//   min-width: 670px;
// }

.ol-rotate {
  top: 3em !important;
}

.ol-attribution {
  display: none !important;
}

.custom-control {
  top: 8px;
  left: 55px;
  width: 65px;
  height: 27px;
  // background: no-repeat url('http://openlayers.org/en/latest/examples/resources/logo-70x70.png');
  // background: no-repeat url('../../assets/legenda-mapa.png');
  background: rgba(42, 102, 155, 0.55);
  font-size: 13px;
  border-style: solid;
  border-color: rgba(255, 240, 190, 0.4);
  border-width: 3px;
}

.custom-control:hover{
  background-color:rgba(38, 92, 142, 1);
  border-style: solid;
  border-color: rgba(255, 240, 190, 0.6);
  border-width: 3px;
}

.custom-control-botao {
  color: #E0E0E0;
  font-size: 13px;
  padding-left: 2px;
}

.custom-control-popup-invisible {
  display: none;
  width: 165px;
  //height: 127px;
  background-color: red;
}

.custom-control-popup-visible {
  display: block;
  width: 200px;
  //height: 127px;
  background-color: #FFFFFF;
  padding: 2px;
}

.exporta-control:hover{
  background-color:rgba(38, 92, 142, 1);
  border-style: solid;
  border-color: rgba(255, 240, 190, 0.6);
  border-width: 3px;
}

.exporta-control {
  top: 8px;
  left: 135px;
  width: 30px;
  height: 28px;
  // background: no-repeat url('http://openlayers.org/en/latest/examples/resources/logo-70x70.png');
  background: no-repeat url('../../assets/legenda-mapa.png');
  background: rgba(42, 102, 155, 0.55);
  font-size: 13px;
  border-style: solid;
  border-color: rgba(255, 240, 190, 0.4);
  border-width: 3px;
  color: #E0E0E0;
  padding-left: 3px;
}


.dot {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.6;
  padding-left: 4px;
}

.dot-2 {
  display: inline-block;
  top: 40%;
  transform: translateY(-40%);
  font-size: 13px;
  color: #505050;
  padding-left: 4px;
}

</style>