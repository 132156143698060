import Vue from "vue";
import { API, API_BLOB, baseURL } from "../../services/API";
import store_site from "../../store/store_site";
import MapaLotes from "../EmpreendMapa/MapaLotes.vue";

import { EventBus } from "../../services/eventbus";

function initialState() {
  return  {
    dados : [],
    vm : {
      vm_filtro: "",
      vm_preco: {},
      vm_preco_options: { preco_min: 0, preco_max: 15000000 },
      vm_area: {},
      vm_area_options: { area_min: 0, area_max: store_site.C_FILTROAVANCADO_VALORMAXIMO },
      vm_localizacao_selected: "",
      vm_localizacao_options: [],
      vm_tipo_selected: "",
      vm_tipo_options: [],
      vm_status_selected: "",
      vm_status_options: store_site.pre_filtro_situacao,
    
      vm_quadra : "Indiferente",
      vm_lote   : "Indiferente",
    
      vm_cod_empreendimento: ""
    },
    usou_filtro_avancado      : false,
    array_cod_empreendimentos : "",
    filtra_empreendimento_total_encontrado: null,

    MapaLotes : MapaLotes,

    empreend_selecionado_hover              : {},
    empreend_selecionado                    : {},

    fotos_galeria              :  [],

    botao_visible : false,
    styleSelecao  : 0,
  }

}

var vm_store_Empreendimentos = new Vue({
  data () {
    return initialState();
  },

  watch: {
    empreend_selecionado_hover() {
      if (this.empreend_selecionado_hover
        && this.empreend_selecionado_hover.cod_empreendimento
        && this.empreend_selecionado_hover.cod_empreendimento > 0
        ) {
          this.empreend_selecionado_hover = this.dados.find(o => o.cod_empreendimento == this.empreend_selecionado_hover.cod_empreendimento);
      }
      // else {
      //   this.empreend_selecionado_hover = {};
      // }
    }
  },

  created() { 
    //console.log("CREATED", vm1);
  },

  methods: {

    resetData() { 
      Object.assign(this.$data, initialState());
    },

    ImagemCapaCaminhoCompleto(img_url) {
      var img = baseURL + img_url;
      return img
    },

    // Filtrando somente Planos de Pagamento com fases liberadas para esta fase
    empreend_selecionado_planos_fase(fase) {

      // Função para converter strings em arrays e substituir strings vazias por null
      function convertToArray(str) {
        if (str === null)
          return [null];
        return str.split(',').map(el => el === '' ? null : el);
      }
      let arr_fase = convertToArray(fase)

      const result = this.empreend_selecionado.planos.filter((o) => arr_fase.includes(o.fase));

      return result

    },

    async filtra_empreendimento(p_params

      // p_filtro = "",
      // p_localizacao = "",
      // p_tipo = "",
      // p_status = "",
      // p_preco_min = "",
      // p_preco_max = "",
      // p_area_min = "",
      // p_area_max = "",
      // p_quadra = "",
      // p_lote = "",
      // p_cod_empreendimento = null,
    ) {

      try {
        // console.log("p_cod_empreendimento:", p_cod_empreendimento);
        // console.log("p_filtro:", p_filtro);
        // console.log("p_localizacao:", p_localizacao);
        // console.log("p_tipo:", p_tipo);
        // console.log("p_status:", p_status);
        // console.log("p_preco_min:", p_preco_min);
        // console.log("p_preco_max:", p_preco_max);
        // console.log("p_area_min:", p_area_min);
        // console.log("p_area_max:", p_area_max);
        // console.log("p_quadra:", p_quadra);
        // console.log("p_lote:", p_lote);
        var params = {};
        if (p_params)
          params = p_params.params;

        var ls_filtros = {};
        params.tipo ? Object.assign(ls_filtros, { tipo: params.tipo }) : "";
        params.localizacao
          ? Object.assign(ls_filtros, { localizacao: params.localizacao })
          : "";
        params.status ? Object.assign(ls_filtros, { status: params.status }) : "";
        (params.preco_min && params.preco_min >= 0)
          ? Object.assign(ls_filtros, { preco_min: params.preco_min })
          : "";
        params.preco_max && params.preco_max != this.vm.vm_preco.preco_max
          ? Object.assign(ls_filtros, { preco_max: params.preco_max })
          : "";
        (params.area_min && params.area_min >= 0)
          ? Object.assign(ls_filtros, { area_min: params.area_min })
          : "";
        params.area_max && params.area_max != this.vm.vm_area.area_max
          ? Object.assign(ls_filtros, { area_max: params.area_max })
          : "";
        (params.quadra && params.quadra != "Indiferente") ? Object.assign(ls_filtros, { quadra: params.quadra }) : "";
        (params.lote && params.lote != "Indiferente")   ? Object.assign(ls_filtros, { lote: params.lote }) : "";

        // Verifica se está usando Filtro Avançado
        if (JSON.stringify(ls_filtros) != "{}")
          this.usou_filtro_avancado = true;
        else  
          this.usou_filtro_avancado = false;

        const response = await API.get("empreendimento_com_filtro", {
          params
        });

        if (response) {
          // Resultado da Consulta -------------------
          if (response.data.result.data == "Não possui dados") {
            //this.results = [];
            this.dados = []
          }
          else {
            //this.results = response.data.result.data;
            this.dados = response.data.result.data;
          }

          // Se existir um empreendimento selecionado, então montar somente dados alterados do filtro e poligono do empreendimento selecionado,
          // senão montar todos os filtros e os poligonos dos empreendimentos
          if (params.cod_empreendimento) {

            // Poligonos das unidades selecionadas
            this.MapaLotes_AtualizaPoligonos("");

          }
          else {

            // Filtro -------------------
            this.vm.filtro = params.filtro;

            // Select de Municipios -------------------
            let ls_array_mun = response.data.result.municipios.map((elem) => ({
              text: elem.municipio,
              value: elem.municipio.substring(0, elem.municipio.indexOf("-")),
            }));
            ls_array_mun.insert(0, { text: "Indiferente", value: "" });
            this.vm.vm_localizacao_options = ls_array_mun;

            // Select de Tipos -------------------
            let ls_array_tipo = response.data.result.tipos.map((elem) => ({
              text: elem.tipo,
              value: elem.tipo,
            }));
            ls_array_tipo.insert(0, { text: "Indiferente", value: "" });
            this.vm.vm_tipo_options = ls_array_tipo;

            // Precos ---------------------------------
            this.vm.vm_preco = response.data.result.precos;
            this.vm.vm_preco.preco_max = Math.round(this.vm.vm_preco.preco_max + 1);
            if (this.vm.vm_preco_options.preco_max == 0)
              this.vm.vm_preco_options.preco_max = this.vm.vm_preco.preco_max;

            // Areas ---------------------------------
            this.vm.vm_area = response.data.result.areas;
            this.vm.vm_area.area_max = Math.round(this.vm.vm_area.area_max + 1);
            if (this.vm.vm_area_options.area_max == 0)
              this.vm.vm_area_options.area_max = this.vm.vm_area.area_max;

            // Quadra --------------------------------
            if (response.data.result.quadra && response.data.result.quadra != "Indiferente")
              this.vm.vm_quadra = response.data.result.quadra;

            // Lote --------------------------------
            if (response.data.result.lote && response.data.result.lote != "Indiferente")
              this.vm.vm_lote = response.data.result.lote;

            // Poligonos dos empreendimentos selecionados
            this.array_cod_empreendimentos = this.dados.map(function(elem) {
              return elem.cod_empreendimento;
            });
            this.array_cod_empreendimentos = JSON.stringify(this.array_cod_empreendimentos)
                                                  .replace('[', '')
                                                  .replace(']', '');
            this.MapaEmpreendimentos_AtualizaPoligonos(this.array_cod_empreendimentos);

            // Contando totais de Lotes Disponiveis Encontrados
            var array_filtra_empreendimento_total_encontrado = await this.dados.map(function(elem) {
              return parseInt(elem.quant_unidades);
            });
            this.filtra_empreendimento_total_encontrado = array_filtra_empreendimento_total_encontrado.reduce((a,b)=>a+b, 0)
          }

          // Grava Estatisticas -------------
          // var ls_filtros = {};
          // params.tipo ? Object.assign(ls_filtros, { tipo: params.tipo }) : "";
          // params.localizacao
          //   ? Object.assign(ls_filtros, { localizacao: params.localizacao })
          //   : "";
          // params.status ? Object.assign(ls_filtros, { status: params.status }) : "";
          // (params.preco_min && params.preco_min >= 0)
          //   ? Object.assign(ls_filtros, { preco_min: params.preco_min })
          //   : "";
          // params.preco_max && params.preco_max != this.vm.vm_preco.preco_max
          //   ? Object.assign(ls_filtros, { preco_max: params.preco_max })
          //   : "";
          // (params.area_min && params.area_min >= 0)
          //   ? Object.assign(ls_filtros, { area_min: params.area_min })
          //   : "";
          // params.area_max && params.area_max != this.vm.vm_area.area_max
          //   ? Object.assign(ls_filtros, { area_max: params.area_max })
          //   : "";
          // (params.quadra && params.quadra != "Indiferente") ? Object.assign(ls_filtros, { quadra: params.quadra }) : "";
          // (params.lote && params.lote != "Indiferente")   ? Object.assign(ls_filtros, { lote: params.lote }) : "";

          if (JSON.stringify(ls_filtros) != "{}") {
            params.cod_empreendimento ? Object.assign(ls_filtros, { cod_empreendimento: params.cod_empreendimento }) : "";
            API.post("/site/estatistica_busca", { filtro: JSON.stringify(ls_filtros) } );
          }

        }
      } catch (err) {
        //console.log("[ERRO] : ", err);
      }
    },

    MapaEmpreendimentos_AtualizaPoligonos(array_cod_empreendimentos) {
    //console.log("MapaEmpreendimentos_AtualizaPoligonos", array_cod_empreendimentos)
      EventBus.$emit("MapaEmpreendimentos_AtualizaPoligonos", array_cod_empreendimentos);
    },

    MapaLotes_AtualizaPoligonos(p_CodEmp) {
      EventBus.$emit("MapaLotes_AtualizaPoligonos", p_CodEmp);
    },

    async MapaImagemByEmpreendimento(cod_empreendimento) {
      const resposta = await API_BLOB.get(`site/mapa_imagem`, {
        params: {
          cod_empreendimento,
        },
      });
      console.log("🚀 ~ file: store_Empreendimentos.js:269 ~ MapaImagemByEmpreendimento ~ resposta:", resposta)
      if (resposta) {
        const file = new Blob([resposta.data], { type: "image/png" });
        const url = URL.createObjectURL(file);
        return url;
      }
    },

  }
})

export default vm_store_Empreendimentos
